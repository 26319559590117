import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, map, mergeMap, Observable, of, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import { dateToUnix } from '@utils/time.utils';

import { RoomType } from '@enums/roomType.enum';

import AddMembersRequestDTO from './models/add-members.request.dto';
import CreateRoomRequestDTO from './models/create-room.request.dto';
import { GenericRoomMapper } from './models/generic-room.mapper';
import GetRoomMembersResponseDTO from './models/get-room-members.response.dto';
import GetRoomResponseDTO from './models/get-room.response.dto';
import GetRoomsResponseDTO from './models/get-rooms.response.dto';
import { RoomMemberMapper } from './models/room-member.mapper';
import RoomDTO from './models/room.dto';
import { RoomMapper } from './models/room.mapper';
import { Room } from '@models/room.class';
import { RoomMembers } from '@models/rooms/room-members.class';

import { DiscoversStore } from '@state/stores/discovers.store';
import { RoomsStore } from '@state/stores/rooms.store';
import { UIStore } from '@state/stores/ui.store';

import { AuthTokenService } from '@services/tokens/auth-token.service';

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  private url: string;

  constructor(
    private http: HttpClient,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore,
    private readonly tokenService: AuthTokenService,
    private readonly uiStore: UIStore
  ) {
    this.url = configuration.apiUrl;
  }

  getRooms(options?: { date?: Date }): Observable<Room[]> {
    let queryParams = new HttpParams();
    // Date in unix format
    if (options?.date)
      queryParams = queryParams.set(
        'changes',
        dateToUnix(options.date).toString()
      );

    return this.http
      .get<GetRoomsResponseDTO>(`${this.url}/users/me/rooms`, {
        params: queryParams,
      })
      .pipe(
        map((response) =>
          response.rooms.map((room) => RoomMapper.fromDTO(room))
        ),
        tap((rooms) => this.roomsStore.add(rooms, { override: true })),
        mergeMap((rooms) => {
          if (rooms.length === 0) return of([]);
          return forkJoin(
            rooms.map((room) =>
              this.getMembers(room.id).pipe(
                map((members) => {
                  room.members = members.members;
                  room.administrators = members.administrators;
                  return room;
                })
              )
            )
          );
        })
      );
  }

  // TODO: Switch back to Room only when server endpoint is updated
  getRoomById(id: number): Observable<Room> {
    return this.http.get<GetRoomResponseDTO>(`${this.url}/rooms/${id}`).pipe(
      map((room) => GenericRoomMapper.fromDTO(room) as Room),
      tap((room) => this.roomsStore.add(room as Room, { override: true })),
      mergeMap((room) =>
        this.getMembers(room.id).pipe(
          map((members) => {
            room.members = members.members;
            room.administrators = members.administrators;
            return room;
          })
        )
      )
    );
  }

  getRoomPicture(id: number): Observable<Blob> {
    return this.http.get(`${this.url}/rooms/${id}/imageprofile`, {
      responseType: 'blob',
    });
  }

  getRoomPictureURL(id: number, includeToken?: boolean): string {
    let queryParams = new HttpParams();
    if (includeToken && this.tokenService.token)
      queryParams = queryParams.set('authorization', this.tokenService.token);

    const httpRequest = new HttpRequest(
      'GET',
      `${configuration.apiUrl}/rooms/${id}/imageprofile`,
      {
        params: queryParams,
      }
    );
    return httpRequest.urlWithParams;
  }

  getMembers(id: number): Observable<RoomMembers> {
    return this.http
      .get<GetRoomMembersResponseDTO>(`${this.url}/rooms/${id}/userlist`)
      .pipe(
        map((response) => {
          return new RoomMembers(
            response.all.map((member) => RoomMemberMapper.fromDTO(member))
          );
        }),
        tap((members) =>
          this.roomsStore.update(id, () => ({
            members: members.members,
            administrators: members.administrators,
          }))
        )
      );
  }

  createRoom(room: CreateRoomRequestDTO): Observable<Room> {
    const formData = new FormData();
    formData.append('room_name', room.name);
    formData.append('room_description', room.description);
    formData.append('room_participants', JSON.stringify(room.members));
    if (room.picture) formData.append('file', room.picture);

    console.log('createRoom formData values', {
      name: formData.get('room_name'),
      description: formData.get('room_description'),
      participants: formData.get('room_participants'),
      file: formData.get('file'),
    });

    return this.http
      .post<RoomDTO>(`${this.url}/rooms/user/createroom`, formData)
      .pipe(
        map((room) => RoomMapper.fromDTO(room)),
        tap((room) => this.roomsStore.add(room, { override: true }))
      );
  }

  updateRoom(id: number, room: Partial<Room>): Observable<void> {
    const formData = new FormData();
    if (room.name) formData.append('name', room.name);
    if (room.description) formData.append('description', room.description);
    if (room.picture) formData.append('file', room.picture);

    return this.http.put<void>(`${this.url}/rooms/${id}`, formData).pipe(
      tap(() =>
        this.roomsStore.update(id, () => ({
          name: room.name,
          description: room.description,
        }))
      )
    );
  }

  addMembers(id: number, members: number[]): Observable<void> {
    const body: AddMembersRequestDTO = {
      users: members,
    };

    return this.http.post<void>(`${this.url}/rooms/${id}/addusers`, body).pipe(
      tap(() => {
        this.roomsStore.update(id, (room) => ({
          members: room.members?.concat(members) ?? members,
        }));
      })
    );
  }

  leaveRoom(id: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/rooms/leaveroom/${id}`).pipe(
      tap(() => {
        this.roomsStore.remove(id);
        if (
          this.uiStore.chat()?.id === id &&
          this.uiStore.chat()?.type === RoomType.Group
        )
          this.uiStore.reset('chat');
      })
    );
  }
}
