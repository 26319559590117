import { NgClass } from '@angular/common';
import { Component, Inject, isDevMode } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import {
  asyncScheduler,
  filter,
  from,
  interval,
  observeOn,
  switchMap,
  tap,
} from 'rxjs';

import { initFlowbite } from 'flowbite';

import { SemanticLevel } from '@models/semantic.enum';

import { AppStore } from '@state/stores/app.store';
import { AuthStore } from '@state/stores/auth.store';
import { UIStore } from '@state/stores/ui.store';

import { AuthService } from '@services/api/auth/auth.service';
import { NotificationsService } from '@services/notifications/notifications.service';
import { ToastService } from '@services/toast/toast.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';

import { LoadingOverlayComponent } from '@views/loading-overlay/loading-overlay.component';
import { StoreViewComponent } from '@views/store-view/store-view.component';

import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { NavigationBarComponent } from '@components/navigation-bar/navigation-bar.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { TitleBarComponent } from '@components/title-bar/title-bar.component';

import { LanguagePrefixer } from '@services/language/prefixer.resolver';
import { AppService } from 'src/pwa/app.service';

import { UnreadChatsTitle } from '@strategies/unread-chats.strategy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    HeaderComponent,
    RouterOutlet,
    NavigationBarComponent,
    FooterComponent,
    StoreViewComponent,
    LoadingOverlayComponent,
    NgClass,
    TitleBarComponent,
    ProgressBarComponent,
  ],
  providers: [AppStore],
})
export class AppComponent {
  // Properties
  authenticated$ = this.authStore.authenticated;
  titlebar$ = this.uiStore.titlebar;
  chat$ = this.uiStore.chat;

  progress$ = this.app.progress$;

  // Lifecycle hooks
  constructor(
    @Inject(AppStore) private readonly appStore: AppStore,
    private readonly app: AppService,
    private readonly swUpdate: SwUpdate,
    private readonly toastService: ToastService,
    private readonly authService: AuthService,
    private readonly authTokenService: AuthTokenService,
    private readonly authStore: AuthStore,
    private readonly uiStore: UIStore,
    // ? Push notifications
    private readonly notificationsService: NotificationsService,
    // ? Resolvers (that need to be initialized)
    private readonly languagePrefixer: LanguagePrefixer,
    private readonly unreadChats: UnreadChatsTitle
  ) {
    // ? Meta tags
    // https://stackoverflow.com/a/57527009
    if (/iPad|iPhone|iPod/.test(navigator.userAgent))
      this.addMaximumScaleToMetaViewport();

    // ? UI
    // initFlowbite();

    // ? Check stored token
    if (this.authTokenService.token != undefined)
      this.authService
        .checkToken()
        .pipe(
          observeOn(asyncScheduler),
          switchMap(() => this.app.load())
        )
        .subscribe();

    // ? Check app health
    /*
    interval(10000)
      .pipe(tap(() => this.app.check()))
      .subscribe();
    */
  }

  ngOnInit(): void {
    // ? Service Workers
    this.swUpdate.versionUpdates
      .pipe(
        tap((event) => {
          switch (event.type) {
            case 'NO_NEW_VERSION_DETECTED':
              this.appStore.set('app', {
                update: 'updated',
              });
              break;
            case 'VERSION_DETECTED':
              this.appStore.set('app', {
                update: 'available',
              });
              /*
                this.toastService.custom(
                'New version available. Downloading in the background...',
                  {
                  id: 'new-version-available',
                    type: SemanticLevel.Info,
                    position: 'top-center',
                  duration: 5000,
                  progressBar: 'none',
                  dismissible: true,
                  }
                );
              */
              break;
            case 'VERSION_READY':
              this.appStore.set('app', {
                update: 'downloaded',
              });
              this.toastService.close('new-version-available');
              this.toastService.custom(
                'A new version has been installed and will be available on the next reload. Would you like to reload now?',
                {
                  id: 'new-version-ready',
                  type: SemanticLevel.Info,
                  position: 'top-center',
                  duration: 10000,
                  dismissible: true,
                  actions: [
                    {
                      text: 'Reload',
                      click: () => {
                        from(this.swUpdate.activateUpdate())
                          .pipe(
                            filter((result) => result),
                            tap(() => {
                              this.appStore.set('app', {
                                update: 'updated',
                              });
                              window.location.reload();
                            })
                          )
                          .subscribe();
                      },
                    },
                  ],
                }
              );
              break;

            case 'VERSION_INSTALLATION_FAILED':
              // TODO: If the app fails to install the new version and/or is in an unrecorevable state, we should notify the user...
              // if (this.swUpdate.unrecoverable)
              /*
              this.toastService.custom(
                'New version failed to install. Please try again.',
                {
                  type: SemanticLevel.Error,
                  position: 'top-center',
                  duration: 3000,
                }
              );
              */
              break;
          }
        })
      )
      .subscribe();

    if (this.swUpdate.isEnabled) {
      // ? Check for updates every 15 minutes
      interval(1000 * 60 * 15)
        .pipe(switchMap(() => from(this.swUpdate.checkForUpdate())))
        .subscribe({
          next: (response) =>
            console.log(
              '[SW] Checking for updates... New version available?',
              response
            ),
          error: (error) => console.error('[SW] Update check failed', error),
        });
    } else {
      console.warn(
        `Service workers are not enabled. The app won't be able to check for updates automatically.`
      );
    }

    // ? UI
    initFlowbite();

    // ? Debugging
    if (isDevMode()) {
      // ? Toast notification
      /*
      this.toastService.custom(
        'Toast notification sent from the ToastService for debugging.',
        {
          type: SemanticLevel.Warning,
          position: 'top-center',
          duration: 10000,
          actions: [
            {
              text: 'Reload',
              click: () => {
                window.location.reload();
              },
            },
          ],
        }
      );
      */
      // ? Badges
      /*
      navigator.setAppBadge(4);
      let count = 0;
      setInterval(() => {
        count++;
        console.log('Setting badge...', count);
        navigator.setAppBadge(count);
      }, 1000);
      */
    }
  }

  // Methods
  private addMaximumScaleToMetaViewport() {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      let re = /maximum\-scale=[0-9\.]+/g;

      if (typeof content === 'string' && re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0']
          .filter((v) => typeof v === 'string')
          .join(', ');
      }

      el.setAttribute('content', content);
    }
  }
}
