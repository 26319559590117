<div class="overflow-hidden rounded-md bg-primary">
  @if (progressBar === 'top') {
    <app-progress-bar
      class="h-2 rounded-t-md border-b"
      [duration]="options?.duration || 5000"
      [theme]="type"
      [callback]="close.bind(this)"
    ></app-progress-bar>
  }

  <div
    class="flex flex-row items-center justify-between gap-4 p-4 shadow-md"
    [ngClass]="{
      'border-success bg-success-light text-success': type === 'success',
      'border-warn bg-warn-light text-warn': type === 'warning',
      'border-danger bg-danger-light text-danger': type === 'error',
      'border-info bg-info-light text-info': type === 'info',
      'rounded-md': !progressBar || progressBar === 'none',
      'rounded-b-md pt-2': progressBar === 'top',
      'rounded-t-md pb-2': progressBar === 'bottom',
    }"
  >
    <div class="flex min-w-0 flex-row items-center gap-2">
      @if (options?.icon) {
        <i class="fad" [ngClass]="'fa-' + options?.icon"></i>
      } @else {
        <i
          class="fad"
          [ngClass]="{
            'fa-check-circle': type === 'success',
            'fa-exclamation-triangle': type === 'warning',
            'fa-exclamation-circle': type === 'error',
            'fa-info-circle': type === 'info',
          }"
        ></i>
      }
      <p
        class="max-w-full flex-grow truncate text-sm"
        style="word-wrap: break-word"
      >
        {{ message.split('\n')[0] }}
      </p>
    </div>
    @if (actions || options?.dismissible) {
      <div class="flex flex-shrink-0 flex-row items-center gap-4">
        @if (actions && actions.length > 0) {
          @for (action of actions; track action.text) {
            <button
              class="whitespace-nowrap rounded-md px-2 py-1 text-sm focus:outline-none"
              [ngClass]="{
                'bg-success text-success-light hover:bg-success-dark focus:bg-success-dark':
                  type === 'success',
                'bg-warn text-warn-light hover:bg-warn-dark focus:bg-warn-dark':
                  type === 'warning',
                'bg-danger text-danger-light hover:bg-danger-dark focus:bg-danger-dark':
                  type === 'error',
                'bg-info text-info-light hover:bg-info-dark focus:bg-info-dark':
                  type === 'info',
              }"
              (click)="action.click()"
            >
              @if (action.icon) {
                <i class="fad" [ngClass]="'fa-' + action.icon"></i>
              }
              {{ action.text }}
            </button>
          }
        }
        @if (options?.dismissible) {
          <button
            class="text-grey hover:text-grey-dark focus:text-grey-dark focus:outline-none"
            (click)="close()"
          >
            <i class="fas fa-times"></i>
          </button>
        }
      </div>
    }
  </div>

  @if (progressBar === 'bottom') {
    <app-progress-bar
      class="h-2 rounded-b-md border-t"
      [duration]="options?.duration || 5000"
      [theme]="type"
      [callback]="close.bind(this)"
    ></app-progress-bar>
  }
</div>
