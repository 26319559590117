import { FileExtension } from '@enums/fileExtensions.enum';
import { MessageType } from '@enums/messageType.enum';

import { FilesService } from '@services/files/files.service';

import { BaseFileMessage, IBaseFileMessage } from './base/FileMessage.base';

// ? Defined in the backend (config.adapter.ts)
enum AllowedExtensions {
  MP4 = FileExtension.MP4,
  MOV = FileExtension.MOV,
  GP3 = FileExtension.GP3,
  OGG = FileExtension.OGG,
  AVI = FileExtension.AVI,
  QT = FileExtension.QT,
}
export const AllowedMIMEs = Object.values(AllowedExtensions).map((ext) =>
  FilesService.mime(ext)
);

export interface IVideoMessage extends IBaseFileMessage {
  type: MessageType.VIDEO;

  extension: AllowedExtensions;
  duration: number;
  // size: number;
  thumbnail: string;
}

export class VideoMessage extends BaseFileMessage implements IVideoMessage {
  override readonly type: MessageType.VIDEO = MessageType.VIDEO;

  override readonly extension!: AllowedExtensions;
  override readonly duration!: number;
  // override readonly size!: number;
  override readonly thumbnail!: string;

  constructor(
    message: Omit<IVideoMessage, 'type' | 'extension'> & { extension: string }
  ) {
    super({ ...message, type: MessageType.VIDEO });
    // Object.assign(this, message); // ? This would override class properties of the base message.
    if (message) {
      // ! This if is necessary for plainToInstance from class-transformer to work
      this.extension = intoAllowedVideoExtensions(message.extension);
      this.duration = message.duration;
      this.thumbnail = message.thumbnail;
    }
  }
}

function intoAllowedVideoExtensions(extension: string) {
  const allowed = Object.values(AllowedExtensions);
  if (!allowed.includes(extension as AllowedExtensions)) {
    throw new Error(`Extension ${extension} is not allowed`);
  }
  return extension as AllowedExtensions;
}
