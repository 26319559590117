{
  "name": "zonetacts-webapp",
  "version": "3.0.1",
  "private": true,
  "engines": {
    "node": ">=20 <21",
    "npm": ">=10 <11"
  },
  "packageManager": "npm@10.9.1",
  "scripts": {
    "tauri": "tauri",
    "vite": "vite",
    "ng": "ng",
    "dev": "ng serve chatprise --configuration development",
    "dev:local": "ng serve chatprise --configuration development,local",
    "vite:dev": "vite serve",
    "start:pwa:chatprise": "ng serve chatprise --configuration development",
    "start:app:chatprise": "npm run tauri dev -- --config src/assets/clients/chatprise/tauri.conf.json",
    "start:ios:chatprise": "npm run tauri ios dev -- --config src/assets/clients/chatprise/tauri.conf.json",
    "start:android:chatprise": "npm run tauri android dev -- --config src/assets/clients/chatprise/tauri.conf.json",
    "build:pwa:chatprise": "ng build chatprise",
    "build:app:chatprise": "npm run tauri build -- --config src/assets/clients/chatprise/tauri.conf.json",
    "build:ios:chatprise": "npm run tauri ios build -- --config src/assets/clients/chatprise/tauri.conf.json",
    "build:android:chatprise": "npm run tauri android build -- --config src/assets/clients/chatprise/tauri.conf.json",
    "start:pwa:emcesa": "ng serve emcesa --configuration development",
    "start:app:emcesa": "npm run tauri dev -- --config src/assets/clients/emcesa/tauri.conf.json",
    "start:ios:emcesa": "npm run tauri ios dev -- --config src/assets/clients/emcesa/tauri.conf.json",
    "start:android:emcesa": "npm run tauri android dev -- --config src/assets/clients/emcesa/tauri.conf.json",
    "build:pwa:emcesa": "ng build emcesa",
    "build:app:emcesa": "npm run tauri build -- --config src/assets/clients/emcesa/tauri.conf.json",
    "build:ios:emcesa": "npm run tauri ios build -- --config src/assets/clients/emcesa/tauri.conf.json",
    "build:android:emcesa": "npm run tauri android build -- --config src/assets/clients/emcesa/tauri.conf.json",
    "start:pwa:icatel": "ng serve icatel --configuration development",
    "start:app:icatel": "npm run tauri dev -- --config src/assets/clients/icatel/tauri.conf.json",
    "start:ios:icatel": "npm run tauri ios dev -- --config src/assets/clients/icatel/tauri.conf.json",
    "start:android:icatel": "npm run tauri android dev -- --config src/assets/clients/icatel/tauri.conf.json",
    "build:pwa:icatel": "ng build icatel",
    "build:app:icatel": "npm run tauri build -- --config src/assets/clients/icatel/tauri.conf.json",
    "build:ios:icatel": "npm run tauri ios build -- --config src/assets/clients/icatel/tauri.conf.json",
    "build:android:icatel": "npm run tauri android build -- --config src/assets/clients/icatel/tauri.conf.json",
    "start:pwa:levantina": "ng serve levantina --configuration development",
    "start:app:levantina": "npm run tauri dev -- --config src/assets/clients/levantina/tauri.conf.json",
    "start:ios:levantina": "npm run tauri ios dev -- --config src/assets/clients/levantina/tauri.conf.json",
    "start:android:levantina": "npm run tauri android dev -- --config src/assets/clients/levantina/tauri.conf.json",
    "build:pwa:levantina": "ng build levantina",
    "build:app:levantina": "npm run tauri build -- --config src/assets/clients/levantina/tauri.conf.json",
    "build:ios:levantina": "npm run tauri ios build -- --config src/assets/clients/levantina/tauri.conf.json",
    "build:android:levantina": "npm run tauri android build -- --config src/assets/clients/levantina/tauri.conf.json",
    "start:pwa:mutua": "ng serve mutua --configuration development",
    "start:app:mutua": "npm run tauri dev -- --config src/assets/clients/mutua/tauri.conf.json",
    "start:ios:mutua": "npm run tauri ios dev -- --config src/assets/clients/mutua/tauri.conf.json",
    "start:android:mutua": "npm run tauri android dev -- --config src/assets/clients/mutua/tauri.conf.json",
    "build:pwa:mutua": "ng build mutua",
    "build:app:mutua": "npm run tauri build -- --config src/assets/clients/mutua/tauri.conf.json",
    "build:ios:mutua": "npm run tauri ios build -- --config src/assets/clients/mutua/tauri.conf.json",
    "build:android:mutua": "npm run tauri android build -- --config src/assets/clients/mutua/tauri.conf.json",
    "start:pwa:uoc": "ng serve uoc --configuration development",
    "start:app:uoc": "npm run tauri dev -- --config src/assets/clients/uoc/tauri.conf.json",
    "start:ios:uoc": "npm run tauri ios dev -- --config src/assets/clients/uoc/tauri.conf.json",
    "start:android:uoc": "npm run tauri android dev -- --config src/assets/clients/uoc/tauri.conf.json",
    "build:pwa:uoc": "ng build uoc",
    "build:app:uoc": "npm run tauri build -- --config src/assets/clients/uoc/tauri.conf.json",
    "build:ios:uoc": "npm run tauri ios build -- --config src/assets/clients/uoc/tauri.conf.json",
    "build:android:uoc": "npm run tauri android build -- --config src/assets/clients/uoc/tauri.conf.json",
    "start:pwa:xategem": "ng serve xategem --configuration development",
    "start:app:xategem": "npm run tauri dev -- --config src/assets/clients/xategem/tauri.conf.json",
    "start:ios:xategem": "npm run tauri ios dev -- --config src/assets/clients/xategem/tauri.conf.json",
    "start:android:xategem": "npm run tauri android dev -- --config src/assets/clients/xategem/tauri.conf.json",
    "build:pwa:xategem": "ng build xategem",
    "build:app:xategem": "npm run tauri build -- --config src/assets/clients/xategem/tauri.conf.json",
    "build:ios:xategem": "npm run tauri ios build -- --config src/assets/clients/xategem/tauri.conf.json",
    "build:android:xategem": "npm run tauri android build -- --config src/assets/clients/xategem/tauri.conf.json",
    "test": "ng test",
    "transpile:sw": "rimraf ./src/pwa/service-workers/dist/*.js && tsc --project ./src/pwa/service-workers/tsconfig.json",
    "lint:angular": "ng lint",
    "lint": "eslint \"{src,test}/**/*.{html,ts}\"",
    "lint:fix": "eslint \"{src,test}/**/*.{html,ts}\" --fix",
    "format": "prettier --check \"{src,test}/**/*.{html,scss,ts,json}\"",
    "format:fix": "prettier --write \"{src,test}/**/*.{html,scss,ts,json}\"",
    "compodoc:build": "compodoc -p tsconfig.doc.json",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s",
    "compodoc:serve": "compodoc -s"
  },
  "dependencies": {
    "@angular/animations": "^19.0.1",
    "@angular/cdk": "^19.0.1",
    "@angular/cdk-experimental": "^19.0.1",
    "@angular/common": "^19.0.1",
    "@angular/compiler": "^19.0.1",
    "@angular/core": "^19.0.1",
    "@angular/fire": "^19.0.0-rc.0",
    "@angular/forms": "^19.0.1",
    "@angular/platform-browser": "^19.0.1",
    "@angular/platform-browser-dynamic": "^19.0.1",
    "@angular/router": "^19.0.1",
    "@angular/service-worker": "^19.0.1",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@kolkov/angular-editor": "^3.0.0-beta.0",
    "@ng-icons/core": "^29.10.0",
    "@ng-icons/lucide": "^29.10.0",
    "@ng-select/ng-select": "^14.0.2",
    "@ngneat/overview": "~6.0.0",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/entity": "^18.1.1",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/signals": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@ngxpert/hot-toast": "^3.0.2",
    "@tauri-apps/cli": "^2.1.0",
    "bs-stepper": "^1.7.0",
    "class-transformer": "^0.5.1",
    "class-variance-authority": "^0.7.0",
    "clsx": "^2.1.1",
    "embla-carousel-angular": "^19.0.0",
    "flag-icons": "^7.2.3",
    "flowbite": "^2.5.2",
    "highlight.js": "^11.10.0",
    "linkify-html": "^4.1.4",
    "linkifyjs": "^4.1.4",
    "mime": "^4.0.4",
    "ng2-pdf-viewer": "^10.3.4",
    "ngx-cookie-service": "^19.0.0",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-quill": "^27.0.0",
    "ngx-scrollbar": "^16.1.0",
    "ngx-sonner": "^2.0.2",
    "quill": "^2.0.2",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.1",
    "shepherd.js": "^14.3.0",
    "socket.io-client": "^2.5.0",
    "string-strip-html": "^13.4.8",
    "sweetalert2": "^11.14.5",
    "tailwindcss-displaymodes": "^1.0.8",
    "tslib": "^2.8.1",
    "uuid": "^11.0.3",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.2",
    "@angular-eslint/builder": "19.0.0",
    "@angular-eslint/eslint-plugin": "19.0.0",
    "@angular-eslint/eslint-plugin-template": "19.0.0",
    "@angular-eslint/schematics": "19.0.0",
    "@angular-eslint/template-parser": "19.0.0",
    "@angular/cli": "~19.0.2",
    "@angular/compiler-cli": "^19.0.1",
    "@babel/generator": "^7.26.2",
    "@babel/parser": "^7.26.2",
    "@babel/traverse": "^7.25.9",
    "@compodoc/compodoc": "^1.1.26",
    "@eslint/compat": "^1.2.3",
    "@ngrx/eslint-plugin": "^18.1.1",
    "@spartan-ng/cli": "^0.0.1-alpha.366",
    "@types/jasmine": "~5.1.4",
    "@types/serviceworker": "^0.0.105",
    "@types/socket.io-client": "^1.4.36",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "^8.15.0",
    "@typescript-eslint/parser": "^8.15.0",
    "@typescript-eslint/utils": "^8.15.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.15.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.6.3",
    "eslint-plugin-html": "^8.1.2",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-unused-imports": "^4.1.4",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.49",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.9",
    "tailwind-merge": "^2.5.4",
    "tailwindcss": "^3.4.15",
    "tailwindcss-animate": "^1.0.7",
    "typescript": "~5.6.3"
  },
  "volta": {
    "node": "18.20.4",
    "npm": "10.8.2"
  }
}
