import { Injectable, isDevMode } from '@angular/core';

import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import {
  CookieService as NgxCookieService,
  SameSite,
} from 'ngx-cookie-service';

import { CookieNotFoundException } from '@errors/exceptions/cookie-not-found.exception';

import { StorageService } from '@services/storage/storage.service';

const ALLOW_COOKIES_KEY = 'allow-cookies';
const COOKIE_TEST_KEY = 'enabled-cookies';

interface CookieOptions {
  expiration?: Date | number | 'session';
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: SameSite;
}

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  private options: CookieOptions = {
    secure: !isDevMode(), // ? This allows cookies to be used in development mode too
    domain: window.location.hostname,
    path: '/', // ? This ensures that the cookie is available in all routes
    sameSite: 'Lax',
    expiration: 90, // ? This sets the cookie expiration to 3 months
  };
  private useStorage = false;

  // private static ALLOW_COOKIES = 'ALLOW_COOKIES';
  constructor(
    private cookieService: NgxCookieService,
    private storageService: StorageService
  ) {
    // ? Check if cookies are allowed
    this.permissionAsked()
      .pipe(
        switchMap((permission) => {
          if (!permission) {
            return this.putById(COOKIE_TEST_KEY, 'true').pipe(
              switchMap(() =>
                this.getById(COOKIE_TEST_KEY).pipe(
                  switchMap(() => this.removeById(COOKIE_TEST_KEY)),
                  catchError(() => {
                    this.useStorage = true;
                    console.warn(
                      'Cookies are not supported. Storage will be used instead.'
                    );
                    return of(undefined);
                  })
                )
              )
            );
          } else
            return this.allowedCookies().pipe(
              tap((allowed) => {
                if (!allowed) {
                  this.useStorage = true;
                  console.warn(
                    'Cookies are not allowed. Storage will be used instead.'
                  );
                }
              })
            );
        })
      )
      .subscribe();
  }

  consent(): Observable<void> {
    return this.putById(ALLOW_COOKIES_KEY, 'true');
  }

  refuse(): Observable<void> {
    this.useStorage = true;
    return of(this.cookieService.getAll()).pipe(
      map((cookies) => {
        Object.keys(cookies).forEach((cookie) =>
          this.storeCookieInStorage(cookie, cookies[cookie])
        );
        this.cookieService.deleteAll();
      }),
      map(() => this.storageService.set(ALLOW_COOKIES_KEY, 'false'))
    );
  }

  permissionAsked(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const storage = this.storageService.check(ALLOW_COOKIES_KEY);
      const cookie = this.cookieService.check(ALLOW_COOKIES_KEY);
      subscriber.next(storage || cookie);
      subscriber.complete();
    });
  }

  allowedCookies(): Observable<boolean> {
    return of(this.cookieService.get(ALLOW_COOKIES_KEY)).pipe(
      map((permission) => permission === 'true')
    );
  }

  putById(
    id: string,
    token: string,
    parameters?: CookieOptions
  ): Observable<void> {
    if (this.useStorage) return of(this.storeCookieInStorage(id, token));

    const options: CookieOptions = {
      ...this.options,
      ...parameters,
    };
    return of(
      this.cookieService.set(
        id,
        token,
        options.expiration === 'session' ? undefined : options.expiration,
        options.path,
        options.domain,
        options.secure,
        options.sameSite
      )
    );
  }

  existsById(id: string): Observable<boolean> {
    if (this.useStorage) return of(this.storageService.check(id));

    return of(this.cookieService.check(id));
  }

  getById(id: string): Observable<string> {
    if (this.useStorage)
      return of(this.storageService.get(id, (value) => value));

    return of(this.cookieService.check(id)).pipe(
      map((exists) => {
        if (exists) return this.cookieService.get(id);
        else throw new CookieNotFoundException(id);
      })
    );
  }

  removeById(id: string, parameters?: CookieOptions): Observable<void> {
    if (this.useStorage) return of(this.storageService.delete(id));

    const options: CookieOptions = {
      ...this.options,
      ...parameters,
    };
    return of(
      this.cookieService.delete(
        id,
        options.path,
        options.domain,
        options.secure,
        options.sameSite
      )
    );
  }

  private storeCookieInStorage(id: string, token: string): void {
    return this.storageService.set(id, token, { encrypt: true });
  }
}
