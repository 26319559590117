import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

// import { Crypto } from '@peculiar/webcrypto';

import { LoggerService } from '@services/logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class CipherService {
  private logger = new LoggerService(this.constructor.name);

  private crypto?: SubtleCrypto = window.crypto.subtle;

  // Lifecycle hooks
  constructor() {}

  // Methods
  hash(algorithm: AlgorithmIdentifier, content: string): Observable<string> {
    if (!this.crypto) throw new Error('Web Crypto API is not available');

    return from(
      this.crypto
        .digest(algorithm, new TextEncoder().encode(content))
        .then((hash) =>
          Array.from(new Uint8Array(hash))
            .map((b) => b.toString(16).padStart(2, '0'))
            .join('')
        )
    );
  }

  /*
  encrypt(content: string, key: CryptoKey): Observable<ArrayBuffer> {
    return from(
      this.crypto.encrypt(
        {
          name: 'AES-GCM',
          iv: window.crypto.getRandomValues(new Uint8Array(12)),
        },
        key,
        new TextEncoder().encode(content)
      )
    );
  }

  decrypt(content: ArrayBuffer, key: CryptoKey): Observable<string> {
    return from(
      this.crypto
        .decrypt(
          {
            name: 'AES-GCM',
            iv: new Uint8Array(content, 0, 12),
          },
          key,
          content.slice(12)
        )
        .then((decrypted) => new TextDecoder().decode(decrypted))
    );
  }
  */
}
