import { plainToInstance } from 'class-transformer';

import { MessageType } from '@enums/messageType.enum';

import { AudioMessage, IAudioMessage } from './messages/audio-message.class';
import {
  ContactMessage,
  IContactMessage,
} from './messages/contact-message.class';
import { FileMessage, IFileMessage } from './messages/file-message.class';
import { IImageMessage, ImageMessage } from './messages/image-message.class';
import {
  ILocationMessage,
  LocationMessage,
} from './messages/location-message.class';
import { ITextMessage, TextMessage } from './messages/text-message.class';
import { IVideoMessage, VideoMessage } from './messages/video-message.class';

export type IMessage =
  | ITextMessage
  | IImageMessage
  | IVideoMessage
  | IAudioMessage
  | IFileMessage
  | ILocationMessage
  | IContactMessage;

export type Message =
  | TextMessage
  | ImageMessage
  | VideoMessage
  | AudioMessage
  | FileMessage
  | LocationMessage
  | ContactMessage;

export function instanceOfMessage(generic?: any): generic is Message {
  return (
    generic instanceof TextMessage ||
    generic instanceof ImageMessage ||
    generic instanceof VideoMessage ||
    generic instanceof AudioMessage ||
    generic instanceof FileMessage ||
    generic instanceof LocationMessage ||
    generic instanceof ContactMessage
  );
}

export function toInstance(generic: IMessage): Message {
  switch (generic.type) {
    case MessageType.TEXT:
      return plainToInstance(TextMessage, generic, {
        enableImplicitConversion: true,
      });
    case MessageType.IMAGE:
      return plainToInstance(ImageMessage, generic, {
        enableImplicitConversion: true,
      });
    case MessageType.VIDEO:
      return plainToInstance(VideoMessage, generic, {
        enableImplicitConversion: true,
      });
    case MessageType.AUDIO:
      return plainToInstance(AudioMessage, generic, {
        enableImplicitConversion: true,
      });
    case MessageType.FILE:
      return plainToInstance(FileMessage, generic, {
        enableImplicitConversion: true,
      });
    case MessageType.LOCATION:
      return plainToInstance(LocationMessage, generic, {
        enableImplicitConversion: true,
      });
    case MessageType.CONTACT:
      return plainToInstance(ContactMessage, generic, {
        enableImplicitConversion: true,
      });
    default:
      return generic;
  }
}
