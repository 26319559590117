import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { configuration } from '@configuration/configuration';

import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'app-store-links',
  imports: [CommonModule],
  templateUrl: './store-links.component.html',
  styleUrl: './store-links.component.scss',
})
export class StoreLinksComponent {
  // Host bindings
  @HostBinding('class') class = 'flex w-full flex-col gap-4';

  // Lifecycle hooks
  constructor(private languageService: LanguageService) {}

  // Methods
  googlePlay() {
    return `https://play.google.com/store/apps/details?id=${
      configuration.ids.Google.android
    }&hl=${this.languageService.currentLanguage()}`;
  }

  appStore() {
    return `https://apps.apple.com/${this.languageService.currentLanguage()}/app/${
      configuration.ids.Apple.iOS
    }`;
  }
}
