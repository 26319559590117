import LinkPreviewContentDTO from '@services/api/messages/models/link-preview-content.dto';
import ReplyMessageDTO from '@services/api/messages/models/reply-message.dto';

export enum ServerFileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'voice',
  FILE = 'file',
}

export default interface IBaseMessageDTO {
  readonly _id: string;
  readonly idUser: number;
  readonly date: string;
  readonly message: string;
  readonly internalId: string; // TODO: What is this?
  readonly modifiedState: number;
  readonly replyMessage?: ReplyMessageDTO;
  readonly fileType?: ServerFileType;
  readonly extension?: string;
  readonly fileDuration?: number;
  readonly thumbnail?: string;
  readonly linkPreview?: LinkPreviewContentDTO;
}
