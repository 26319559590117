import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import linkifyHtml from 'linkify-html';
import { Opts } from 'linkifyjs';
import { stripHtml } from 'string-strip-html';

@Injectable({
  providedIn: 'root',
})
export class HtmlUtils {
  private static newLineTags = [
    'br',
    'div',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'li',
    'ul',
    'ol',
  ];

  constructor(private http: HttpClient) {}

  static isHTML(message: string) {
    // Check if the message string is an HTML string (i.e. it contains HTML tags)
    const doc = new DOMParser().parseFromString(message, 'text/html');
    return (
      Array.from(doc.body.childNodes).some((node) => node.nodeType === 1) ||
      stripHtml(message).result !== message
    );
  }

  static extractPlain(message: string): string {
    if (HtmlUtils.isHTML(message)) {
      const doc = new DOMParser().parseFromString(message, 'text/html');
      return this.toPlain(doc.body);
    } else {
      return message;
    }
  }

  static extractLines(
    node: Node,
    options: { lines: number; chars?: number; plain?: boolean }
  ): string {
    if (options.plain) {
      const text = this.toPlain(node);

      const lines = text
        .split('\n')
        .slice(0, options.lines)
        .map((line) => {
          const substring = line.trim().substring(0, options.chars);
          return `${substring}${
            line.trim().length > substring.length ? '...' : ''
          }`;
        });
      if (text.split('\n').length > options.lines) lines.push('...');
      return lines.join('\n');
    } else {
      const text = this.toHTML(node);

      const lines = text
        .split('\n')
        .slice(0, options.lines)
        .map((line) => {
          if (options.chars) {
            const substring = HtmlUtils.substring(line.trim(), options.chars);
            const originalLength = this.toPlain(
              new DOMParser().parseFromString(line, 'text/html').body
            ).length;
            const substringLength = this.toPlain(
              new DOMParser().parseFromString(substring, 'text/html').body
            ).length;
            return `${substring}${
              originalLength > substringLength ? '...' : ''
            }`;
          } else return line;
        });
      if (text.split('\n').length > options.lines) lines.push('...');
      return lines.join('\n');
    }
  }

  static substring(message: string, end: number): string {
    let text = '';
    const node = new DOMParser().parseFromString(message, 'text/html').body;

    let length = 0;
    Array.from(node.childNodes).forEach((child) => {
      if (length >= end) return;

      if (child.nodeType === Node.TEXT_NODE) {
        if (child.textContent) {
          if (length + child.textContent.length > end) {
            text += child.textContent.substring(0, end - length);
            length = end;
          } else {
            text += child.textContent;
            length += child.textContent.length;
          }
        }
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        const element = child as Element;
        text += `<${element.tagName}>${this.substring(
          this.toHTML(child),
          end - length
        )}</${element.tagName}>`;
        length += this.toPlain(child).length;
      }
    });

    return text;
  }

  private static toPlain(node: Node): string {
    let text = '';

    // ? If you find a any HTML tag that creates a new line, add a new line to the text
    Array.from(node.childNodes).forEach((child) => {
      if (HtmlUtils.newLineTags.includes(child.nodeName.toLowerCase()))
        text += '\n';

      if (child.nodeType === Node.TEXT_NODE) text += child.textContent;
      else text += this.toPlain(child);
    });
    return text;
  }

  private static toHTML(node: Node): string {
    let text = '';

    // ? If you find a any HTML tag that creates a new line, add a new line to the text
    Array.from(node.childNodes).forEach((child) => {
      if (HtmlUtils.newLineTags.includes(child.nodeName.toLowerCase())) {
        text += '\n';
        return;
      }

      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent;
      } else {
        const element = child as Element;
        text += `<${element.tagName}>${this.toHTML(child)}</${
          element.tagName
        }>`;
      }
    });
    return text;
  }

  private extractLines(
    node: Node,
    options: { lines: number; limit?: number } = { lines: 1 }
  ): string {
    const text = HtmlUtils.toPlain(node);
    const lines = text
      .split('\n')
      .slice(0, options.lines)
      .map((line) => line.trim().substring(0, options.limit));
    return lines.join('\n');
  }

  static sanytizeHTML(message: string): string {
    const withoutAll = stripHtml(message, {
      ignoreTags: ['b', 'u', 'i', ...HtmlUtils.newLineTags],
    }).result;

    const final = stripHtml(withoutAll, {
      ignoreTags: ['b', 'u', 'i', 'ul', 'li'],
      cb: ({ tag, deleteFrom, deleteTo, rangesArr }) => {
        if (HtmlUtils.newLineTags.includes(tag.name)) {
          if (deleteFrom && deleteTo)
            return rangesArr.push([
              deleteFrom,
              deleteTo,
              tag.slashPresent ? '' : '\n',
            ]);
        }
        if (deleteFrom && deleteTo)
          return rangesArr.push([deleteFrom, deleteTo, '']);
      },
    }).result;

    console.log('Replaced HTML', { prev: message, final });
    return final;
  }

  static replaceURLs(message: string, options?: Opts): string {
    return linkifyHtml(message, {
      formatHref: (href) => {
        const protocols = /^(https?|ftp|mailto|tel|file):\/\//i;
        if (protocols.test(href)) return href;
        return `https://${href}`;
      },
      defaultProtocol: 'https',
      className: 'text-primary hover:underline dark:text-primary-vlight',
      target: (href: string) => {
        const domain = new URL(href).hostname;
        return domain === window.location.hostname ? '_self' : '_blank';
      },
      ...options,
    });
  }
}
