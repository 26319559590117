// ? This is an interceptor for HTTP requests (and responses) to the Zonetacts server and our APIs.

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { configuration } from '@configuration/configuration';

import { instanceOfAppError } from '@errors/app.error';

import { AlertService } from '@services/alert/alert.service';
import { NetworkService } from '@services/network/network.service';
import { ToastService } from '@services/toast/toast.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';
import { RegistrationTokenService } from '@services/tokens/registration-token.service';

@Injectable()
export class ZonetactsInterceptor implements HttpInterceptor {
  private url: string;

  constructor(
    private networkService: NetworkService,
    private authTokenService: AuthTokenService,
    private registrationTokenService: RegistrationTokenService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private alertService: AlertService
  ) {
    this.url = configuration.apiUrl;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // console.debug('[ZonetactsInterceptor] Running');

    // ? Request
    const authKey = 'authorization';
    if (request.url.startsWith(this.url) && !request.headers.has(authKey)) {
      if (this.authTokenService.token) {
        request = request.clone({
          setHeaders: {
            // TODO: Replace with the standard header when the server is ready
            // Authorization: `Bearer ${this.authTokenService.token}`,
            [authKey]: this.authTokenService.token,
          },
        });
      } else if (
        this.registrationTokenService.token &&
        request.url.startsWith(`${this.url}/postregister`)
      ) {
        request = request.clone({
          setHeaders: {
            // TODO: Replace with the standard header when the server is ready
            // Authorization: `Bearer ${this.registrationTokenService.token}`,
            [authKey]: this.registrationTokenService.token,
          },
        });
      }
    }

    // ? Response
    return next.handle(request).pipe(
      /*
      tap((event) => {
        console.debug('[ZonetactsInterceptor] Event intercepted', event);
      }),
      */
      catchError((error) => {
        if (!this.networkService.browser$()) {
          this.toastService.error('You are offline. Request failed');
          throw new Error('Browser is offline...');
        }
        /*
        // ! When we start the app, and it checks if the stored token is valid, we sometimes get false positives saying the server is down because the socket hasn't connected yet?
        else if (!this.networkService.server$.value) {
          this.toastService.error(
            'The server is down... We are working on it.'
          );
          throw new Error('Server is down...');
        }
        */

        // console.debug('[ZonetactsInterceptor] Error intercepted', error);
        if (request.url.startsWith(this.url)) {
          if (error instanceof HttpErrorResponse) {
            // ? Show an alert if the request is not silent, so the user can see the error
            if (!request.headers.has('Silent')) {
              switch (true) {
                case error.error.error && instanceOfAppError(error.error.error):
                  this.toastService.error(
                    error.error.error.detail[
                      this.translateService.currentLang
                    ] || error.error.error.detail['en']
                  );
                  break;
                default:
                  this.toastService.error(error.error.message || error.message);
              }
            }

            // TODO: Handle other HTTP errors
            /*
            switch (error.status) {
              case 401:
                console.log('401');
                break;
              case 403:
                console.log('403');
                break;
              case 404:
                console.log('404');
                break;
              case 500:
                console.log('500');
                break;
              default:
                console.log('default');
            }
            */
          }
        }
        return throwError(() => error);
      })
    );
  }
}
