import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  constructor() {
    return;
  }

  set<T>(
    key: string,
    value: T,
    options?: {
      expiration?: Date;
    }
  ): void {
    sessionStorage.setItem(
      key,
      JSON.stringify({
        data: value,
        date: new Date(),
        expiration: options?.expiration,
      })
    );
  }

  check(key: string): boolean {
    return sessionStorage.getItem(key) !== null;
  }

  get<T>(key: string, mapper?: (value: any) => T): T | null {
    return this.getWithDate<T>(key, mapper)?.data || null;
  }

  getWithDate<T>(
    key: string,
    mapper?: (value: any) => T
  ): { date: Date; data: T } | null {
    const json = sessionStorage.getItem(key);
    if (!json) return null;

    const parsed = JSON.parse(json);
    if (parsed.expiration) {
      const expired: boolean =
        new Date().getTime() > new Date(parsed.expiration).getTime();
      if (expired) {
        sessionStorage.removeItem(key);
        return null;
      }
    }
    return {
      date: new Date(parsed.date),
      data: mapper ? mapper(parsed.data) : (parsed.data as T),
    };
  }

  delete(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    sessionStorage.clear();
  }
}
