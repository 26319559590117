<button
  type="button"
  class="fill absolute flex items-center justify-center"
  (click)="reload()"
>
  <i
    class="fad fa-sync"
    [ngClass]="{
      'text-danger': colorize() && update$() !== 'updated',
    }"
  ></i>
</button>
