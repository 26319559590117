import { Injectable, signal } from '@angular/core';

import { catchError, of, take } from 'rxjs';

import { CookiesService } from '@services/cookies/cookies.service';

const TOKEN_KEY = 'registration-token';

// TODO: Use the private state token API to get the token from other ZT trusted domains
// ? https://developers.google.com/privacy-sandbox/protections/private-state-tokens?hl=es-419

@Injectable({
  providedIn: 'root',
})
export class RegistrationTokenService {
  // Properties
  token$ = signal<string | undefined>(undefined);

  // Lifecycle hooks
  constructor(private cookiesService: CookiesService) {
    // Set the service's token and expiration signals from the cookie values
    this.cookiesService
      .getById(TOKEN_KEY)
      .pipe(
        take(1),
        catchError(() => of(undefined))
      )
      .subscribe((token) => {
        this.token$.set(token);
      });
  }

  get token(): string | undefined {
    return this.token$();
  }

  set token(token: string | undefined) {
    if (!token || token.length === 0)
      this.cookiesService.removeById(TOKEN_KEY).subscribe();
    else
      this.cookiesService
        .putById(TOKEN_KEY, token, {
          expiration: 1, // 24 hours
        })
        .subscribe();
    this.token$.set(token);
  }
}
