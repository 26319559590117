<div
  #progressBar
  role="progressbar"
  aria-valuemin="0"
  aria-valuemax="100"
  class="h-full w-0"
  [ngClass]="{
    'transition-[width] ease-linear': duration(),
  }"
  [ngStyle]="{
    'transition-duration': duration() + 'ms',
  }"
></div>
