import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import { dateToUnix } from '@utils/time.utils';

import GetUsersResponseDTO from './models/get-users.response.dto';
import { UserMapper } from './models/user.mapper';
import UsersChanges from '@models/users-changes.interface';

import { UsersStore } from '@state/stores/users.store';

import { AuthTokenService } from '@services/tokens/auth-token.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private url: string;

  constructor(
    private http: HttpClient,
    private readonly tokenService: AuthTokenService,
    private readonly usersStore: UsersStore
  ) {
    this.url = configuration.apiUrl;
  }

  getUsers(options?: { date?: Date }): Observable<UsersChanges> {
    let queryParams = new HttpParams();
    // Date in unix format
    if (options?.date)
      queryParams = queryParams.set(
        'changes',
        dateToUnix(options.date).toString()
      );

    return this.http
      .get<GetUsersResponseDTO>(
        `${this.url}/users/list${options?.date ? '/changes' : ''}`,
        options?.date ? { params: queryParams } : undefined
      )
      .pipe(
        map((response) => {
          return {
            users: response.all.map((user) => UserMapper.fromDTO(user)),
            deleted: response.deleted,
          };
        }),
        tap((changes) => {
          this.usersStore.add(changes.users, { update: true });
          this.usersStore.remove(changes.deleted);
        })
      );
  }

  /*
  // ? Unnecessary, use getUsers() instead with the date option
  getUserChanges(date: Date): Observable<GetUsersResponseDTO> {
    return this.http.get<GetUsersResponseDTO>(`${this.url}/users/list`);
  }
  */

  /*
  getUserById(id: string): Observable<User> {
    return this.http.get<User>(`${this.url}/users/${id}`);
  }
  */

  getUserProfilePicture(id: number): Observable<Blob> {
    /*
    this.indexedDB.get<string>(`user-${id}-picture`).pipe(
      switchMap((picture) => {
        if (picture) return picture;
        else
          return this.http.get(`${this.url}/users/${id}/imageprofile`, {
              responseType: 'blob',
            }).pipe(
              switchMap((response) => {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                return new Observable<string>((observer) => {
                  reader.onloadend = () => {
                    observer.next(reader.result as string);
                    observer.complete();
                  };
                }).pipe(
                  switchMap((picture) =>
                    this.indexedDB.set(`user-${id}-picture`, picture).pipe(
                      map(() =>
                        UserActions.loadUserPictureSuccess({
                          id,
                          picture: picture,
                        })
                      ),
                      catchError((error) =>
                        of(UserActions.loadUserPictureError({ error }))
                      )
                    )
                  ),
                  catchError((error) =>
                    of(UserActions.loadUserPictureError({ error }))
                  )
                );
              })
          );
      }),
      catchError((error) => of(UserActions.loadUserPictureError({ error })))
    )
    */
    // TODO: Add options to check and cache the image in the IndexedDB
    return this.http.get(`${this.url}/users/${id}/imageprofile`, {
      responseType: 'blob',
    });
  }

  getUserProfilePictureURL(id: number, includeToken?: boolean): string {
    let queryParams = new HttpParams();
    if (includeToken && this.tokenService.token)
      queryParams = queryParams.set('authorization', this.tokenService.token);

    const httpRequest = new HttpRequest(
      'GET',
      `${this.url}/users/${id}/imageprofile`,
      {
        params: queryParams,
      }
    );
    return httpRequest.urlWithParams;
  }
}
