import { AsyncPipe, NgClass } from '@angular/common';
import { Component, computed, HostBinding } from '@angular/core';

import { Theme } from '@enums/theme.enum';

import { UIStore } from '@state/stores/ui.store';

import { ThemeService } from '@services/theme/theme.service';

@Component({
  selector: 'app-theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss'],
  imports: [NgClass, AsyncPipe],
})
export class ThemeSelectorComponent {
  // Host bindings
  @HostBinding('class') class = 'cursor-pointer relative';
  // Enums
  Theme = Theme;

  // Properties
  theme$ = computed(() => this.uiStore.theme());

  // Lifecycle hooks
  constructor(
    private readonly uiStore: UIStore,
    private readonly themeService: ThemeService
  ) {}

  // Methods
  toggleTheme() {
    this.themeService.toggleDarkMode();
  }
}
