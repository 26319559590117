import { RoomType } from '@enums/roomType.enum';

import { BaseRoom, Dates, IBaseRoom } from './rooms/base/Room.base';

interface ExtendedDates<T = Date> extends Dates<T> {
  pictureChanged?: T; // TODO: Determine if we really need this or not
  lastEvent?: T;
}

export interface IAlert extends IBaseRoom {
  type: RoomType.Alert;
  dates: ExtendedDates<Date>;

  members?: number[];
  administrators?: number[];
  pagination?: {
    page: number;
    date: Date;
  };

  priority?: number;
}

export class Alert extends BaseRoom implements IAlert {
  override readonly type!: RoomType.Alert;
  override readonly dates!: ExtendedDates<Date>;

  override members?: number[];
  override administrators?: number[];

  constructor(room: Omit<IAlert, 'type'>) {
    super({ ...room, type: RoomType.Alert });

    Object.assign(this, room);
  }
}

export function instanceOfAlert(generic?: any): generic is Alert {
  return generic instanceof Alert && generic.type === RoomType.Alert;
}
