import { computed, Injectable, isDevMode, signal } from '@angular/core';
import { Router } from '@angular/router';

import {
  asyncScheduler,
  forkJoin,
  map,
  Observable,
  observeOn,
  switchMap,
  tap,
} from 'rxjs';

import { AuthStore } from '@state/stores/auth.store';
import { DiscoversStore } from '@state/stores/discovers.store';
import { MessagesStore } from '@state/stores/messages.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';
import { UIStore } from '@state/stores/ui.store';
import { UsersStore } from '@state/stores/users.store';

import { DiscoversService } from '@services/api/discovers/discovers.service';
import { MessagesService } from '@services/api/messages/messages.service';
import { OTOsService } from '@services/api/otos/otos.service';
import { ProfileService } from '@services/api/profile/profile.service';
import { GenericRoomsService } from '@services/api/rooms/generic-rooms.service';
import { RoomsService } from '@services/api/rooms/rooms.service';
import { UsersService } from '@services/api/users/users.service';
import { CookiesService } from '@services/cookies/cookies.service';
import { FcmService } from '@services/firebase/cloud-messaging/fcm.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';
import { RegistrationTokenService } from '@services/tokens/registration-token.service';

import { ZTSocket } from '@sockets/zonetacts/zonetacts.socket';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(
    private readonly router: Router,
    private readonly socket: ZTSocket,

    private readonly profileService: ProfileService,
    private readonly usersService: UsersService,
    private readonly otosService: OTOsService,
    private readonly roomsService: RoomsService,
    private readonly discoversService: DiscoversService,
    private readonly genericRoomsService: GenericRoomsService,
    private readonly messagesService: MessagesService,

    private readonly authTokenService: AuthTokenService,
    private readonly registrationTokenService: RegistrationTokenService,
    private readonly fcmService: FcmService,
    private readonly cookiesServce: CookiesService,

    private readonly authStore: AuthStore,
    private readonly userStore: UsersStore,
    private readonly otosStore: OTOsStore,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore,
    private readonly messagesStore: MessagesStore,
    private readonly uiStore: UIStore
  ) {}

  pending$ = signal<string[]>([]);
  completed$ = signal<string[]>([]);
  progress$ = computed(() => {
    if (this.pending$().length === 0 && this.completed$().length === 0)
      return 0;
    else
      return (
        (this.completed$().length /
          (this.pending$().length + this.completed$().length)) *
        100
      );
  });

  check(): void {
    console.debug('Checking...');
    // TODO: This should validate our current URL and app state based on the token, and redirect to the correct page if needed
  }

  reset(): void {
    this.socket.connect();

    this.authTokenService.token = undefined;
    this.registrationTokenService.token = undefined;
    this.fcmService.deleteToken().pipe(observeOn(asyncScheduler)).subscribe();

    this.authStore.reset();
    this.userStore.reset();
    this.otosStore.reset();
    this.roomsStore.reset();
    this.discoversStore.reset();
    this.messagesStore.reset();
    this.uiStore.reset();
  }

  load(date?: Date) {
    if (date) return this.changes(date);
    else return this.everything();
  }

  private completeTask = (task: string) => {
    this.pending$.set(this.pending$().filter((t) => t !== task));
    this.completed$.set([...this.completed$(), task]);

    if (isDevMode())
      console.debug('Tasks', {
        completed: task,
        pending: this.pending$(),
      });
  };

  private everything(): Observable<void> {
    if (isDevMode()) console.debug('Loading data...');

    this.pending$.set([
      'Load profile',
      'Load users',
      'Load OTOs',
      'Load rooms',
      'Load discovers',
      'Load messages',
    ]);
    this.completed$.set([]);

    return forkJoin([
      this.profileService.loadProfile().pipe(
        tap(() => this.completeTask('Load profile')),
        switchMap(() =>
          // ? We only know the tenant id after we load the profile, for now
          this.cookiesServce
            .getById('TENANT')
            .pipe(tap((tenant) => this.socket.connect(tenant)))
        )
      ),
      this.usersService
        .getUsers()
        .pipe(tap(() => this.completeTask('Load users'))),
      this.genericRoomsService
        .getAllOTOs()
        .pipe(tap(() => this.completeTask('Load OTOs'))),
      this.genericRoomsService
        .getAllRooms()
        .pipe(tap(() => this.completeTask('Load rooms'))),
      this.discoversService
        .getDiscovers()
        .pipe(tap(() => this.completeTask('Load discovers'))),
      this.genericRoomsService
        .getAllAlerts()
        .pipe(tap(() => this.completeTask('Load Alerts'))),
      this.messagesService
        .getLatestMessages(10)
        .pipe(tap(() => this.completeTask('Load messages'))),
    ]).pipe(
      // observeOn(asyncScheduler),
      map(() => {})
    );
  }

  private changes(date: Date): Observable<void> {
    if (isDevMode()) console.debug('Loading changes since', date);

    this.pending$.set([
      'Load profile',
      'Load users',
      // 'Load OTOs',
      'Load rooms',
      'Load discovers',
      // 'Load messages',
    ]);
    this.completed$.set([]);

    return forkJoin([
      this.profileService.loadProfile().pipe(
        tap(() => this.completeTask('Load profile')),
        switchMap(() =>
          this.cookiesServce
            .getById('TENANT')
            .pipe(tap((tenant) => this.socket.connect(tenant)))
        )
      ),
      this.usersService
        .getUsers()
        .pipe(tap(() => this.completeTask('Load users'))),
      // this.otosService
      this.roomsService
        .getRooms({ date })
        .pipe(tap(() => this.completeTask('Load rooms'))),
      this.discoversService
        .getDiscovers({ date })
        .pipe(tap(() => this.completeTask('Load discovers'))),
    ]).pipe(
      observeOn(asyncScheduler),
      map(() => undefined)
    );
  }
}
