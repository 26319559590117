import { ModalContent } from '@enums/modal.enum';
import { Sidebar } from '@enums/sidebar.enum';
import { Theme } from '@enums/theme.enum';

import { Chat } from '@models/chat.interface';

export interface UISidebar {
  content: Sidebar;
  chat?: Chat;
  message?: string;
}

export interface UIModal {
  content: ModalContent;
  chat?: Chat;
  message?: string;
}

// export interface UIState extends LoadingState {
export interface UIState {
  theme: Theme;
  titlebar: 'custom' | 'default';
  language: string | undefined;

  chat: Chat | undefined;
  sidebar: UISidebar | undefined;
  modal: UIModal | undefined;
}

export const initialUIState: UIState = {
  theme: window.matchMedia('(prefers-color-scheme: dark)').matches
    ? Theme.Dark
    : Theme.Light,
  titlebar:
    'windowControlsOverlay' in navigator &&
    // @ts-expect-error - TS doesn't know about windowControlsOverlay
    navigator.windowControlsOverlay.visible
      ? 'custom'
      : 'default',
  language: undefined,

  chat: undefined,

  sidebar: undefined,
  modal: undefined,
};
