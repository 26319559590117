import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { v4 as uuid } from 'uuid';

import { configuration } from '@configuration/configuration';

import { dateToUnix } from '@utils/time.utils';

import { RoomType } from '@enums/roomType.enum';

import { HistoricOptions } from './models/historic-options.interface';
import UpdatePointerRequestDTO from './models/mark-as-readed.request.dto';
import { MessageMapper } from './models/message.mapper';
import { PaginationOptions } from './models/pagination-options.interface';
import ResendMessageRequestDTO from './models/resend-message.request.dto';
import GetAllGenericRoomsResponseDTO from '../rooms/models/get-all-generic-rooms.response.dto';
import { Chat } from '@models/chat.interface';
import { MessageStatus } from '@models/message-status.class';
import { Message } from '@models/message.type';
import { IBaseMessage } from '@models/messages/base/Message.base';
import { Draft } from '@models/messages/draft.interface';
import { TextMessage } from '@models/messages/text-message.class';
import { getRoomTypeFromServer } from '@services/api/rooms/models/generic-room.dto';
import { OTOMessageOutgoingEventDTO } from '@sockets/zonetacts/models/oto-message.outgoing.dto';
import { RoomMessageOutgoingEventDTO } from '@sockets/zonetacts/models/room-message.outgoing.dto';

import { AlertsStore } from '@state/stores/alerts.store';
import { AuthStore } from '@state/stores/auth.store';
import { DiscoversStore } from '@state/stores/discovers.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';

import { MessagesStore } from '@stores/messages.store';

import { OTOMessagesService } from './oto-messages.service';
import { RoomMessagesService } from './room-messages.service';

import { OutgoingEvent } from '@sockets/zonetacts/zonetacts.events';
import { ZTSocket } from '@sockets/zonetacts/zonetacts.socket';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private url: string;
  private me$ = this.authStore.me;

  constructor(
    private http: HttpClient,
    private socket: ZTSocket,
    private OTOsMessagesService: OTOMessagesService,
    private roomsMessagesServices: RoomMessagesService,
    private readonly authStore: AuthStore,
    private readonly otosStore: OTOsStore,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore,
    private readonly alertsStore: AlertsStore,
    private readonly messagesStore: MessagesStore
  ) {
    this.url = configuration.apiUrl;

    // TODO: When the app regains connection, the unsynchronized messages should be resent
  }

  // ? This selects from the userRoom table the following fields: upperRead and lastCleanReadCount for a specific room with the id provided and the user authenticated (MySQL)
  // ? It then returns an interval, depending on the room properties (unlocked history, creation date, etc) (MySQL)
  // ?  Older date (Upper):
  // ?    If the upperRead field is null
  // ?      It selects the lastCleanReadCount field, and if it is null, it depends on the unlocked history property of the room
  // ?        If the room has the locked history and the user joined it after the provided date, it returns the date in which the user joined the room
  // ?        Otherwise, it returns the provided date in the request body
  // ?    If the upperRead field is not null
  // ?      If the room has the locked history and the user joined it after the provided date, it returns the date in which the user joined the room
  // ?      Otherwise, it returns the provided date in the request body
  // ?  Newer date (Down): It selects the date provided (MySQL)
  // ?    If the upperRead field is null
  // ?      It selects the lastCleanReadCount field, and if it is null, it returns the date in which the request was received
  // ?    If the upperRead field is not null, it returns the upperRead date
  // ? It then updates the upperRead field in the userRoom table with the older date (upper), in the room with the id provided and the user authenticated (MySQL)
  // ? Then selects all messages in the room between the older and newer dates from the previously calculated interval (MongoDB)
  // ? It marks them all as readed (in the date the request was received, not the date provided in the body) for the user authenticated by emitting an event to the Kafka queue, which will be processed in the microservice to update the status of the messages (MongoDB)
  updatePointer(idRoom: number, date: Date): Observable<void> {
    const body: UpdatePointerRequestDTO = {
      date: dateToUnix(date),
    };

    return this.http.put<void>(`${this.url}/rooms/${idRoom}/upper`, body);
  }

  sendMessage(chat: Chat, message: Draft): Observable<void> {
    const id = uuid();
    const me = this.me$();
    const reply = message.reply
      ? this.messagesStore.findByServerId(message.reply)
      : undefined;

    if (!me) throw new Error('User not authenticated');
    const base: Omit<IBaseMessage, 'type'> = {
      id: id,
      ids: {
        sender: me,
        receiver: chat.type === RoomType.OTO ? chat.id : undefined,
        room: chat.type !== RoomType.OTO ? chat.id : undefined,
        replyId: message.reply,
      },
      message: message.message || '',
      dates: {
        sentDate: new Date(),
      },
      reply: reply
        ? {
            id: reply.id,
            sender: reply.ids.sender,
            message: reply.message || '',
            file: '',
            date: reply.dates.sentDate,
            state: reply.state,
          }
        : undefined,
      synchronized: false,
      sentAttempts: 0,
    };
    // TODO: Handle file messages such as images, videos, etc.
    this.messagesStore.add(new TextMessage(base));

    return of(undefined).pipe(
      tap(() => {
        switch (chat.type) {
          case RoomType.OTO:
            this.otosStore.update(chat.id, () => ({ draft: undefined }));
            return this.socket.outgoingEvent<OTOMessageOutgoingEventDTO>(
              OutgoingEvent.OTO_MESSAGE,
              new OTOMessageOutgoingEventDTO({
                internalId: id,
                toIdUser: chat.id,
                message: message.message || '',
                idMessageReply: message.reply,
              })
            );
          case RoomType.Group:
            this.roomsStore.update(chat.id, () => ({ draft: undefined }));
            return this.socket.outgoingEvent<RoomMessageOutgoingEventDTO>(
              OutgoingEvent.ROOM_MESSAGE,
              new RoomMessageOutgoingEventDTO({
                internalId: id,
                idRoom: chat.id,
                message: message.message || '',
                idMessageReply: message.reply,
              })
            );
          case RoomType.Discover:
            this.roomsStore.update(chat.id, () => ({ draft: undefined }));
            this.discoversStore.update(chat.id, () => ({ draft: undefined }));
            return this.socket.outgoingEvent<RoomMessageOutgoingEventDTO>(
              OutgoingEvent.ROOM_MESSAGE,
              new RoomMessageOutgoingEventDTO({
                internalId: id,
                idRoom: chat.id,
                message: message.message || '',
                idMessageReply: message.reply,
              })
            );
          case RoomType.Alert:
            this.roomsStore.update(chat.id, () => ({ draft: undefined }));
            this.alertsStore.update(chat.id, () => ({ draft: undefined }));
            return this.socket.outgoingEvent<RoomMessageOutgoingEventDTO>(
              OutgoingEvent.ROOM_MESSAGE,
              new RoomMessageOutgoingEventDTO({
                internalId: id,
                idRoom: chat.id,
                message: message.message || '',
                idMessageReply: message.reply,
              })
            );
        }
      })
    );
  }

  getMessages(
    chat: Chat,
    options?: HistoricOptions | PaginationOptions
  ): Observable<Message[]> {
    let observable: Observable<Message[]>;
    switch (chat.type) {
      case RoomType.OTO:
        observable = this.OTOsMessagesService.getMessages(chat.id, options);
        break;
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        observable = this.roomsMessagesServices.getMessages(chat.id, options);
        break;
    }
    return observable.pipe(
      tap((messages) => this.messagesStore.add(messages, { override: true }))
    );
  }

  getLatestMessages(amount?: number): Observable<Message[]> {
    if (!amount)
      return this.http
        .get<GetAllGenericRoomsResponseDTO>(`${this.url}/users/me/alllistroom`)
        .pipe(
          map((response) =>
            response.list
              // ? Filter out rooms with no messages
              .filter((room) => room.lastMessage && room.lastMessage._id)
              .map((room) => room.lastMessage)
              .map((message) => MessageMapper.fromDTO(message))
          ),
          tap((messages) => {
            if (messages.length > 0)
              this.messagesStore.add(messages, { override: true });
          }),
          catchError((error) => {
            console.error(error);
            if (error.cause) console.debug('Error cause', error.cause);
            return [];
          })
        );
    else
      return this.http
        .get<GetAllGenericRoomsResponseDTO>(`${this.url}/users/me/alllistroom`)
        .pipe(
          // ? Filter out rooms with no messages
          map((response) =>
            response.list.filter(
              (room) => room.lastMessage && room.lastMessage._id
            )
          ),
          switchMap((rooms) => {
            if (rooms.length === 0) return of([]);
            return forkJoin(
              rooms.map((room) =>
                this.getMessages(
                  { id: room.id, type: getRoomTypeFromServer(room.type) },
                  { amount }
                )
              )
            ).pipe(map((messages) => messages.flat()));
            /*
            return from(rooms).pipe(
              concatMap((room) =>
                this.getMessages(
                  { id: room.id, type: getRoomTypeFromServer(room.type) },
                  { amount }
                )
              )
            );
            */
          })
        );
  }

  sendFile(chat: Chat, file: File, draft: Draft): Observable<Message> {
    const id = uuid();
    const me = this.me$();

    if (!me) throw new Error('User not authenticated');
    const base: Omit<IBaseMessage, 'type'> = {
      id: id,
      ids: {
        sender: me,
        receiver: chat.type === RoomType.OTO ? chat.id : undefined,
        room: chat.type !== RoomType.OTO ? chat.id : undefined,
        replyId: draft.reply,
      },
      message: draft.message || '',
      dates: {
        sentDate: new Date(),
      },
      synchronized: false,
      sentAttempts: 0,
    };

    switch (chat.type) {
      case RoomType.OTO:
        return this.OTOsMessagesService.sendFile(
          chat.id,
          id,
          file,
          draft.message || '',
          draft.reply
        ).pipe(map((message) => new TextMessage(base)));
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        return this.roomsMessagesServices
          .sendFile(chat.id, id, file, draft.message || '', draft.reply)
          .pipe(map((message) => new TextMessage(base)));
    }
  }

  getFile(
    chat: Chat,
    message: string,
    params?: {
      authorization?: string;
      deviceid?: string;
      requestid?: string;
    }
  ): Observable<Blob> {
    switch (chat.type) {
      case RoomType.OTO:
        return this.OTOsMessagesService.getFile(chat.id, message, params);
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        return this.roomsMessagesServices.getFile(chat.id, message, params);
    }
  }

  getFileURL(
    chat: Chat,
    message: string,
    params?: {
      authorization?: string;
      deviceid?: string;
      requestid?: string;
    }
  ): string {
    switch (chat.type) {
      case RoomType.OTO:
        return this.OTOsMessagesService.getFileURL(chat.id, message, params);
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        return this.roomsMessagesServices.getFileURL(chat.id, message, params);
    }
  }

  getStatus(id: string): Observable<MessageStatus> {
    // TODO: Remove the store dependency when the endpoint is generic (it only needs the message id)
    const message = this.messagesStore.get(id);
    if (!message) throw new Error('Message not found');
    if (!message.ids.server) throw new Error('Message not synchronized');

    const chat: Chat = {
      id: (message.ids.room || message.ids.receiver)!,
      type: message.ids.room ? RoomType.Group : RoomType.OTO,
    };
    if (!chat) throw new Error('Chat for the message not found');

    let observable: Observable<MessageStatus>;
    switch (chat.type) {
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        observable = this.roomsMessagesServices.getMessageStatus(
          message.ids.server,
          chat.id
        );
        break;
      case RoomType.OTO:
      default:
        // return new Observable<MessageStatus>();
        throw new Error('Chat type not supported');
    }
    return observable.pipe(
      tap((status) => {
        this.messagesStore.update(id, () => ({ status }));
      })
    );
  }

  deleteMessage(id: string): Observable<string> {
    // TODO: Remove the store dependency when the endpoint is generic (it only needs the message id)
    const message = this.messagesStore.get(id);
    if (!message) throw new Error('Message not found');
    if (!message.ids.server) throw new Error('Message not synchronized');

    const chat: Chat = {
      id: (message.ids.room || message.ids.receiver)!,
      type: message.ids.room ? RoomType.Group : RoomType.OTO,
    };
    if (!chat) throw new Error('Chat for the message not found');

    switch (chat?.type) {
      case RoomType.OTO:
        return this.OTOsMessagesService.deleteMessage(
          chat.id,
          message.ids.server
        );
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        return this.roomsMessagesServices.deleteMessage(
          chat.id,
          message.ids.server
        );
      default:
        throw new Error('Chat type not supported');
    }
  }

  resendMessage(id: string, chats: Chat[]): Observable<void> {
    // TODO: Remove the store dependency when the endpoint is generic (it only needs the message id)
    const message = this.messagesStore.get(id);
    if (!message) throw new Error('Message not found');
    if (!message.ids.server) throw new Error('Message not synchronized');

    const chat: Chat = {
      id:
        message.ids.room ?? Math.min(message.ids.sender, message.ids.receiver!),
      type: message.ids.room ? RoomType.Group : RoomType.OTO,
    };
    if (!chat) throw new Error('Chat for the message not found');

    const body: ResendMessageRequestDTO = {
      fromIdMessage: message.ids.server,
      fromIdRoom: chat.id,
      oneToOne: chat.type === RoomType.OTO ? 1 : 0,
      destinations: chats.map((chat) => ({
        toIdRoom: chat.id,
        oneToOne: chat.type === RoomType.OTO ? 1 : 0,
      })),
    };
    return this.http.post<void>(
      `${this.url}/rooms/messages/resendmessage`,
      body
    );
  }

  readMessages(chat: Chat, amount?: number): Observable<void> {
    const date = new Date();
    const messages = amount || 0;

    switch (chat?.type) {
      case RoomType.OTO:
        return this.OTOsMessagesService.readMessages(chat.id, date, messages);
      case RoomType.Group:
      case RoomType.Discover:
      case RoomType.Alert:
        return this.roomsMessagesServices.readMessages(chat.id, date, messages);
      default:
        return new Observable<void>();
    }
  }
}
