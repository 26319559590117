import { RoomType } from '@enums/roomType.enum';

import { BaseRoom, Dates, IBaseRoom } from './rooms/base/Room.base';

interface ExtendedDates<T = Date> extends Dates<T> {
  pictureChanged?: T; // TODO: Determine if we really need this or not
  lastEvent?: T;
}

export interface IRoom extends IBaseRoom {
  type: RoomType.Group;
  dates: ExtendedDates<Date>;

  members?: number[];
  administrators?: number[];
  pagination?: {
    page: number;
    date: Date;
  };

  priority?: number;
}

export class Room extends BaseRoom implements IRoom {
  override readonly type!: RoomType.Group;
  override readonly dates!: ExtendedDates<Date>;

  override members?: number[];
  override administrators?: number[];

  constructor(room: Omit<IRoom, 'type'>) {
    super({ ...room, type: RoomType.Group });

    Object.assign(this, room);
  }
}

export function instanceOfRoom(generic?: any): generic is Room {
  return generic instanceof Room && generic.type === RoomType.Group;
}
