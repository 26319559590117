<!-- ? Custom Titlebar in PWAs -->
<!-- https://developer.mozilla.org/en-US/docs/Web/API/Window_Controls_Overlay_API -->
<!-- https://web.dev/articles/window-controls-overlay -->

<h1 class="text-xs text-white">
  @if (unread$() > 0) {
    ({{ unread$() }})
  }
  {{ configuration.title }}
</h1>
