import { Injectable, isDevMode } from '@angular/core';

import { Observable, Subscription, take, takeUntil, tap, timer } from 'rxjs';

import { connect } from 'socket.io-client';

import { configuration } from '@configuration/configuration';

import { ZTSocketHandlerService } from './handler.service';
import { NetworkService } from '@services/network/network.service';
import { BadgeService } from '@services/notifications/badge.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';

import { SocketEvent } from '../socket-io.events';
import { IncomingEvent, OutgoingEvent } from './zonetacts.events';

const TAG = 'ZT';
@Injectable({
  providedIn: 'root',
})
export class ZTSocket {
  // ? https://socket.io/docs/v2/client-installation/
  private socket!: SocketIOClient.Socket;

  constructor(
    private handlerService: ZTSocketHandlerService,
    private readonly networkService: NetworkService,
    private readonly tokenService: AuthTokenService,
    private badgeService: BadgeService
  ) {
    // ? This will create the socket and connect it automatically to the default namespace
    this.create();
    this.socket.connect();
  }

  // Socket properties
  get status(): boolean {
    return this.socket.connected;
  }

  get id() {
    return this.socket.id;
  }

  // Socket methods
  create(namespace?: string | number): void {
    // ? Socket configuration
    const url = namespace
      ? `${configuration.origin}/${namespace}`
      : configuration.origin;
    const options: SocketIOClient.ConnectOpts = {
      ...configuration.sockets,
      ...(this.tokenService.token && {
        query: {
          ...(namespace
            ? {
                authorization: this.tokenService.token,
              }
            : undefined),
        },
      }),
      /*
      TODO: Enable this instead of the query param when the server is ready to handle it
      auth: {
        token: this.tokenService.token,
      }
      */
      // secure: !isDevMode(), // ? This is not necessary, as the server will handle the secure connection
    };

    if (isDevMode())
      console.debug(`[${TAG} Socket] Connecting socket to ${url}`);
    this.socket = connect(url, options);

    /*
    console.debug(
      `[${TAG} Socket] Socket created. Connection settings:`,
      options
    );
    */

    // ? Socket successfully connected (or reconnected)
    this.socket.on(SocketEvent.Connect, () => {
      if (isDevMode())
        console.debug(`[${TAG} Socket] Socket connected to ${url}`);

      // ? Subscribe to the incoming events
      if (namespace) {
        if (isDevMode())
          console.debug('[ZT Socket] Subscribing to incoming events...');
        Object.values(IncomingEvent).forEach((event) =>
          this.incomingEvent(event as IncomingEvent)
        );
        if (isDevMode())
          console.debug(
            '[ZT Socket] Subscribed to incoming events',
            this.subscriptions
          );
      }

      this.networkService.server$.set(true);
    });

    // ? Socket successfully disconnected
    this.socket.on(SocketEvent.Disconnect, (reason: string, details: any) => {
      if (isDevMode())
        console.debug(`[${TAG} Socket] Socket disconnected`, {
          reason,
          details,
        });
      this.networkService.server$.set(false);
    });

    // ? Socket fails to connect (or reconnect)
    this.socket.on(SocketEvent.ConnectError, (error: any) => {
      if (isDevMode())
        console.error(`[${TAG} Socket] Socket connect error`, error);
    });

    // ? Socket throws an error
    this.socket.on(SocketEvent.Error, (error: any) => {
      if (isDevMode()) console.error(`[${TAG} Socket] Socket error`, error);
    });

    // ? Socket receives a ping from the server
    this.socket.on(SocketEvent.Ping, () => {
      // console.debug(`[${TAG} Socket] Socket ping`);
      // if (isDevMode()) this.socket.emit("pong");
      // ? Method to check if the token is expired and refresh it if it is (asap)
      /*
      if (
        this.tokenService.expiration &&
        this.tokenService.expiration < new Date()
      ) { }
      */
    });

    // ? Socket tries to reconnect
    this.socket.on(SocketEvent.ReconnectAttempt, (attempt: number) => {
      if (isDevMode())
        console.debug(`[${TAG} Socket] Socket reconnect attempt nº ${attempt}`);
    });

    // ? Socket succesfully reconnected
    this.socket.on(SocketEvent.Reconnect, (attempt: number) => {
      if (isDevMode())
        console.debug(
          `[${TAG} Socket] Socket reconnected succesfully on the attempt nº ${attempt}`
        );
    });
  }

  connect(namespace?: string) {
    namespace = namespace?.startsWith('/')
      ? namespace.substring(1)
      : namespace || 'socket.io';

    // ? If the socket is not created, create it
    if (!this.socket) {
      // console.debug(`[${TAG} Socket] Socket not created, creating...`);
      this.create(namespace);
      // console.debug(`[${TAG} Socket] Socket created`, this.socket);
    }

    // ? If the socket is already connected, but to a different namespace, invalidate the socket and create a new one
    if (this.socket.nsp !== `/${namespace}`) {
      /*
      console.debug(
        `[${TAG} Socket] Socket already exists for a different namespace, invalidating and creating a new one`,
        this.socket
      );
      */
      this.invalidate();
      if (namespace.length > 0) this.create(namespace);
      else this.create();
    }
    // ? If the socket is already connected, return
    if (this.socket.connected) {
      // console.debug(`[${TAG} Socket] Socket already connected`);
      return;
    }

    // ? Connect the socket
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  invalidate() {
    try {
      if (isDevMode()) console.debug(`[${TAG} Socket] Unsubscribing events...`);
      this.cancelAllEvents();
      this.socket.disconnect();
      if (isDevMode()) console.debug(`[${TAG} Socket] Socket disconnected`);
    } catch (error) {
      console.error({
        message: 'An error occured while invalidating the socket',
        error,
      });
    }
  }

  // Socket events
  // ? Necessary list to keep track of the subscribed incoming events
  // private events: { [key: string]: Observable<any> } = {};
  private subscriptions: { [key: string]: Subscription } = {};

  outgoingEvent<T>(event: OutgoingEvent, data: T, mapper?: (data: T) => any) {
    // TODO: Postpone/queue the event if the socket is not connected or the token is expired, and retry when the socket is connected and the token is refreshed
    if (mapper) data = mapper(data);
    console.debug(`[${TAG} Socket] '${event}' event emitted`, data);
    this.socket.emit(event, data);
  }

  incomingEvent<T>(
    event: IncomingEvent,
    options?: { amount?: number; timeOut?: Date | number }
    // ): Observable<T> {
  ): void {
    let observable: Observable<T> =
      options?.amount === 1
        ? new Observable<T>((observer) => {
            this.socket.once(`${event}`, (data: T) => {
              observer.next(data);
              observer.complete();
            });
          })
        : new Observable<T>((observer) => {
            this.socket.on(`${event}`, (data: T) => {
              observer.next(data);
            });
          });
    if (options?.timeOut) {
      // ? If the timeOut parameter is specified, the observable will complete after the specified time
      if (typeof options.timeOut === 'number')
        observable = observable.pipe(takeUntil(timer(options.timeOut)));
      else
        observable = observable.pipe(
          takeUntil(timer(options.timeOut.getTime() - Date.now()))
        );
    }
    if (options?.amount && options?.amount > 1)
      // ? If the events paramater is specified, the observable will emit the event the specified number of times and then complete
      observable = observable.pipe(take(options.amount));

    // ? Add the event to the events list
    // this.events[event] = observable;
    // return observable;

    // ? Subscribe and store the subscription
    const subscription = observable
      .pipe(
        tap((data) =>
          console.debug(`[${TAG} Socket] '${event}' event received`, data)
        )
        /*
        tap(() =>
          this.badgeService
            .setBadgeContent(Math.round(Math.random() * 100))
            .subscribe()
        )
        */
      )
      .subscribe(this.handlerService.handlers[event]);
    this.addSubscription(event, subscription);
  }

  cancelEvent(event: string): void {
    this.socket.off(event);
    this.deleteSubscription(event);
  }

  private cancelAllEvents(): void {
    Object.keys(this.subscriptions).forEach((event) => this.cancelEvent(event));
    // Safe guard to remove all listeners
    this.socket.removeAllListeners();
  }

  private addSubscription(event: string, subscription: Subscription): void {
    this.deleteSubscription(event);
    this.subscriptions[event] = subscription;
  }

  private deleteSubscription(event: string): void {
    if (this.subscriptions[event] && !this.subscriptions[event].closed)
      this.subscriptions[event].unsubscribe();
    delete this.subscriptions[event];
  }
}
