<!-- Resend modal -->
<div
  tabindex="-1"
  class="fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden bg-grey-light bg-opacity-50 dark:bg-grey-dark dark:bg-opacity-50 md:inset-0"
>
  <div class="relative flex min-h-full flex-col items-center justify-center">
    <div
      class="relative w-full max-w-[90%] rounded-2xl bg-grey-vlight dark:bg-grey-dark sm:max-w-md"
    >
      <div class="my-10 flex items-center justify-center lg:my-20">
        <app-logo class="h-12 lg:h-16"></app-logo>
      </div>

      <div class="overflow-hidden rounded-md">
        <app-progress-bar
          class="h-2 rounded-t-md"
          [progress]="progress()"
          [theme]="'primary'"
        ></app-progress-bar>
      </div>
    </div>
  </div>
</div>
