import { AsyncPipe, NgClass } from '@angular/common';
import {
  Component,
  computed,
  effect,
  ElementRef,
  HostBinding,
  viewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { asyncScheduler, observeOn } from 'rxjs';

import { Store } from '@ngrx/store';

import { Dropdown } from 'flowbite';

import { AuthStore } from '@state/stores/auth.store';
import { UsersStore } from '@state/stores/users.store';

import { selectUrl } from '@selectors/router.selectors';

import { AuthService } from '@services/api/auth/auth.service';

import { UserPictureComponent } from '../user-picture/user-picture.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  imports: [NgClass, UserPictureComponent, RouterLink, AsyncPipe],
})
export class UserMenuComponent {
  // Host bindings
  @HostBinding('class') class = 'overflow-hidden';

  // Properties
  url$ = this.store.select(selectUrl({ includeLanguage: false }));
  private me$ = this.authStore.me;
  profile$ = computed(() =>
    this.me$() ? this.usersStore.get(this.me$()!) : undefined
  );

  private dropdown?: Dropdown;
  private dropdownRef = viewChild.required<ElementRef<HTMLElement>>('dropdown');
  private get dropdownElement() {
    return this.dropdownRef().nativeElement;
  }
  private dropdownButtonRef =
    viewChild.required<ElementRef<HTMLButtonElement>>('dropdownButton');
  private get dropdownButtonElement() {
    return this.dropdownButtonRef().nativeElement;
  }

  // Lifecycle hooks
  constructor(
    private store: Store,
    private readonly authStore: AuthStore,
    private readonly usersStore: UsersStore,
    private readonly authService: AuthService
  ) {
    effect(() => {
      const refs = [this.dropdownRef(), this.dropdownButtonRef()];
      if (refs.every((ref) => ref && ref.nativeElement)) this.createDropdown();
    });
  }

  ngOnDestroy() {
    this.dropdown?.destroy();
  }

  // Methods
  private createDropdown() {
    if (this.dropdown) this.dropdown.destroy();
    this.dropdown = new Dropdown(
      this.dropdownElement,
      this.dropdownButtonElement,
      {
        placement: 'top',
        delay: 0,
      }
    );
  }

  logout() {
    this.authService.logout().pipe(observeOn(asyncScheduler)).subscribe();
  }

  toggleDropdown() {
    this.dropdown?.toggle();
  }
}
