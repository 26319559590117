import UnregisteredUser from '@models/unregistered-user.interface';
import { User } from '@models/user.class';

import { LoadingState } from './loading.state';

export interface AuthState extends LoadingState {
  me: User['id'] | undefined; // ? When a user is authenticated
  info: UnregisteredUser | undefined; // ? When a user hasn't filled the post-register data
  text: string | undefined; // ? To store the legal text
  registered: boolean; // ? To know if a user has filled the post-register data or not...
}

export const initialAuthState: AuthState = {
  loading: false,
  error: null,

  me: undefined,
  info: undefined,
  text: undefined,
  registered: false,
};
