import { MessageType } from '@enums/messageType.enum';

import {
  BaseMessage,
  IBaseMessage,
  LinkPreviewContent,
} from './base/Message.base';

export interface ITextMessage extends IBaseMessage {
  type: MessageType.TEXT;

  preview?: LinkPreviewContent;
}

export class TextMessage extends BaseMessage implements ITextMessage {
  override readonly type: MessageType.TEXT = MessageType.TEXT;

  readonly preview?: LinkPreviewContent;

  constructor(message: Omit<ITextMessage, 'type'>) {
    super({ ...message, type: MessageType.TEXT });

    if (message) {
      // ! This if is necessary for plainToInstance from class-transformer to work
      this.preview = message.preview;
    }
  }
}
