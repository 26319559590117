import { Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'; // Import necessary form-related modules
import { RouterLink } from '@angular/router'; // Import Router for navigation

import { LogoComponent } from '../../components/logo/logo.component';
import { ProgressBarComponent } from '../../components/progress-bar/progress-bar.component';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  imports: [
    ReactiveFormsModule,
    RouterLink,
    LogoComponent,
    ProgressBarComponent,
  ],
})
export class LoadingOverlayComponent {
  // Inputs & Outputs
  progress = input.required<number>();

  // Lifecycle hooks
  constructor() {}
}
