import { AsyncPipe, NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';

import { NetworkService } from '@services/network/network.service';

@Component({
  selector: 'app-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.scss'],
  imports: [NgClass, AsyncPipe],
})
export class OnlineStatusComponent {
  // Inputs & Outputs
  colorize = input<boolean>(false);
  hide = input({ online: false, offline: false });

  // Properties
  browser$ = computed(() => this.networkService.browser$());
  server$ = computed(() => this.networkService.server$());

  constructor(private networkService: NetworkService) {}
}
