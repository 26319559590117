import { RoomType } from '@enums/roomType.enum';

import { MessageDTO } from '../../messages/models/message.dto';

export enum ServerRoomType {
  Group = 1,
  OTO = 2,
  Discover = 3,
  Alert = 4,
}

export function getRoomTypeFromServer(type: ServerRoomType): RoomType {
  switch (type) {
    case ServerRoomType.OTO:
      return RoomType.OTO;
    case ServerRoomType.Group:
      return RoomType.Group;
    case ServerRoomType.Discover:
      return RoomType.Discover;
    case ServerRoomType.Alert:
      return RoomType.Alert;
    default:
      throw new Error(`Unknown room type: ${type}`);
  }
}

export default interface GenericRoomDTO {
  readonly type: ServerRoomType;
  readonly id: number; // ! For discovers, beware that this is NOT the idDiscover, this is the idRoom
  readonly idDiscover?: number; // ! For discovers, we will inject this from our store as a workaround until the server properly implements discovers with their exclusive ids
  readonly name: string;
  readonly description: string;
  readonly lastActivity: string;
  readonly membersCount: number;
  readonly readCount: number;
  readonly createdAt: string;
  readonly lastModification: string;
  readonly lastImageChange: string;
  readonly lastMessage: MessageDTO;
  readonly bidirectional: number;
}
