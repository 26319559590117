import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  catchError,
  combineLatest,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { configuration } from '@configuration/configuration';

// import { FcmService } from '@services/firebase/cloud-messaging/fcm.service';

import { instanceOfAppError } from '@errors/app.error';

import CheckTokenResponseDTO from './models/check-token.response.dto';
import GetSessionsResponseDTO from './models/get-sessions.response.dto';
import { LoginRequestDTO } from './models/login.request.dto';
import LoginResponseDTO from './models/login.response.dto';
import RecoverPasswordRequestDTO from './models/recover-password.request.dto';
import RefreshTokenRequestDTO from './models/refresh-token.request.dto';
import ResetPasswordRequestDTO from './models/reset-password.request.dto';
import { SessionMapper } from './models/session.mapper';
import { Session } from '@models/session.class';

import { AuthStore } from '@state/stores/auth.store';

import { ProfileService } from '@services/api/profile/profile.service';
import { CipherService } from '@services/cipher/cipher.service';
import { CookiesService } from '@services/cookies/cookies.service';
import {
  FCM_TAG,
  FcmService,
} from '@services/firebase/cloud-messaging/fcm.service';
import { SystemService } from '@services/system/system.service';
import { ToastService } from '@services/toast/toast.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';
import { RegistrationTokenService } from '@services/tokens/registration-token.service';

import { ZTSocket } from '@sockets/zonetacts/zonetacts.socket';

import { version } from 'package.json';
import { AppService } from 'src/pwa/app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private readonly app: AppService,
    private authTokenService: AuthTokenService,
    private registrationTokenService: RegistrationTokenService,
    private profileService: ProfileService,
    private fcmService: FcmService,
    private cookiesService: CookiesService,
    private toastService: ToastService,
    private socket: ZTSocket,
    private readonly authStore: AuthStore,
    private readonly systemService: SystemService,
    private readonly cipherService: CipherService
  ) {
    this.url = configuration.apiUrl;
  }

  login(identifier: string, password: string): Observable<boolean> {
    // ? When subscribing to a selector, we must use the take operator to avoid memory leaks
    const hashed = configuration.encryption
      ? this.cipherService.hash('SHA-512', password)
      : of(password);
    const fcmToken = this.fcmService.token().pipe(
      // ? This is to avoid sending an empty token. Currently, our login endpoint doesn't accept empty FCM tokens
      map((token) => (token && token.length > 0 ? token : ' ')),
      catchError(() => of(' '))
    );

    return combineLatest([hashed, fcmToken]).pipe(
      switchMap(([hashed, fcmToken]) => {
        const body: LoginRequestDTO = {
          loginIdentifier: identifier,
          password: hashed,
          token: fcmToken,
          appVersion: version,
          deviceId: this.systemService.deviceId,
          deviceModel: this.systemService.model,
          deviceType: 'Web',
          systemVersion: this.systemService.os,
        };

        return this.http
          .post<LoginResponseDTO>(`${this.url}/login/user`, body)
          .pipe(
            tap((response) => this.handleLogin(response)),
            switchMap((response) => {
              if (response.requiredRegister)
                return from(this.router.navigate(['/auth/register']));
              else {
                return from(this.router.navigate(['/chat'])).pipe(
                  switchMap(() => this.app.load().pipe(map(() => true)))
                );
              }
            })
          );
      })
    );
  }

  externalLogin(token: string): Observable<boolean> {
    const fcmToken = this.cookiesService.getById(FCM_TAG).pipe(
      map((token) => (token && token.length > 0 ? token : ' ')), // ? This is to avoid sending an empty token
      catchError(() => of(' '))
    );

    return fcmToken.pipe(
      switchMap((fcmToken) => {
        const body: LoginRequestDTO = {
          externalToken: token,
          token: fcmToken,
          appVersion: version,
          deviceId: this.systemService.deviceId,
          deviceModel: this.systemService.model,
          deviceType: 'Web',
          systemVersion: this.systemService.os,
        };

        return this.http
          .post<LoginResponseDTO>(`${this.url}/login/user`, body)
          .pipe(
            tap((response) => this.handleLogin(response)),
            switchMap((response) => {
              if (response.requiredRegister)
                return from(this.router.navigate(['/auth/register']));
              else {
                return from(this.router.navigate(['/chat'])).pipe(
                  switchMap(() => this.app.load().pipe(map(() => true)))
                );
              }
            })
          );
      })
    );
  }

  private handleLogin(response: LoginResponseDTO): void {
    // TODO: Get the expiration from the response when implemented
    const expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);

    if (response.requiredRegister) {
      this.toastService.info('You need to register first');
      this.registrationTokenService.token = response.authToken;
      this.authStore.set('registered', false);
    } else {
      this.fcmService.listen();
      this.authTokenService.token = response.authToken;
      this.authStore.set('registered', true);
    }
  }

  logout(): Observable<void> {
    return this.http
      .post<void>(`${this.url}/users/logout`, {})
      .pipe(map(() => this.handleLogout()));
  }

  private handleLogout(): void {
    this.app.reset();
    this.router.navigate(['/auth/login']);
  }

  // TODO: This checks if the current token was revoked/expired, and returns a new one if it was
  checkToken(token?: string): Observable<void> {
    return this.http
      .get<CheckTokenResponseDTO>(
        `${this.url}/users/checktoken`,
        token
          ? {
              headers: {
                authorization: token,
              },
            }
          : {}
      )
      .pipe(
        map((response) => {
          if (response.oldExpired === 1)
            this.authTokenService.token = response.token;
        }),
        catchError((error) => {
          if (error.error.error && instanceOfAppError(error.error.error)) {
            const code = error.error.error.code;
            const details = error.error.error.detail;
            if (code === 202) {
              this.authTokenService.token = undefined;
              this.app.reset();
              this.router.navigate(['/auth/login']);
            }
          }
          throw error;
        })
      );
  }

  refreshFCMToken(token: string): Observable<void> {
    const body: RefreshTokenRequestDTO = {
      token,
      deviceId: '',
      deviceType: 'Web',
    };

    return this.http.post<void>(`${this.url}/users/refreshfirebasetoken`, body);
    // .pipe(map(() => this.tokenService.token));
  }

  recoverPassword(identifier: string): Observable<void> {
    // ? This will send an email to the user with a link to reset the password
    const body: RecoverPasswordRequestDTO = {
      loginIdentifier: identifier,
    };

    return this.http.put<void>(`${this.url}/misc/resetpassword`, body);
  }

  resetPassword(password: string): Observable<void> {
    // ? This will set a new password to the user of the token provided in the Header's Authorization
    const body: ResetPasswordRequestDTO = {
      password,
    };

    return this.http.put<void>(`${this.url}/misc/changepassword`, body);
  }

  getSessions(): Observable<Session[]> {
    return this.http
      .get<GetSessionsResponseDTO>(`${this.url}/users/me/sessions`)
      .pipe(
        map((response) =>
          response.list.map((session) => SessionMapper.fromDTO(session))
        )
      );
  }
}
