import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  HostBinding,
  input,
  viewChild,
} from '@angular/core';

import { ThemeType, transformIntoTailwindTheme } from '@models/theme.type';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  imports: [AsyncPipe, NgClass, NgStyle],
})
export class ProgressBarComponent {
  // Host bindings
  @HostBinding('class') class = `block overflow-hidden`; // ? Flex container with items centered

  // Inputs & Outputs
  theme = input<ThemeType>(); // ? Optional theme of the progress bar

  container = input<string>(); // ? Optional classes that will be applied to the progress bar container
  bar = input<string>(); // ? Optional classes that will be applied to the progress bar

  progress = input<number>(); // ? Progress in percentage
  duration = input<number>(); // ? Duration in milliseconds
  callback = input<() => void>(); // ? Callback function to be called when the progress bar reaches 100%

  // Properties
  private progressBarRef =
    viewChild.required<ElementRef<HTMLElement>>('progressBar');
  private get progressBarElement(): HTMLElement {
    return this.progressBarRef().nativeElement;
  }

  // Lifecycle hooks
  constructor() {
    effect(() => {
      // ? Set the theme of the progress bar
      if (this.theme()) {
        this.class += ` bg-${transformIntoTailwindTheme(
          this.theme()!
        )}-light border-${transformIntoTailwindTheme(this.theme()!)}-dark`;

        this.progressBarElement.classList.add(
          `bg-${transformIntoTailwindTheme(this.theme()!)}`
        );
      }
      if (this.bar()) this.progressBarElement.classList.add(this.bar()!);

      if (this.progress()) {
        this.progressBarElement.style.width = `${this.progress()}%`;
      } else if (this.duration() && this.duration()! > 0) {
        // ? Trigger the animation by setting the width to 100%
        setTimeout(() => {
          this.progressBarElement.style.width = '100%';
        }, 1);

        // ? Set a timeout to call the callback function when the animation ends
        setTimeout(() => {
          if (
            this.callback !== undefined &&
            typeof this.callback === 'function'
          )
            this.callback();
        }, this.duration());
      }
    });
  }
}
