import { Component, HostBinding, output } from '@angular/core';

import { StoreLinksComponent } from '@components/store-links/store-links.component';

import { LogoComponent } from '../../components/logo/logo.component';

@Component({
  selector: 'app-store-view',
  templateUrl: './store-view.component.html',
  styleUrl: './store-view.component.scss',
  imports: [StoreLinksComponent, LogoComponent],
})
export class StoreViewComponent {
  // Host bindings
  @HostBinding('class') class =
    'min-h-dvh flex flex-col items-center w-full p-12 overflow-y-auto bg-grey-vlight dark:bg-grey-dark';

  // Inputs & outputs
  standaloneMode = output<boolean>();

  // Properties

  // Lifecycle hooks
  constructor() {}

  // Methods
  use() {
    this.standaloneMode.emit(true);
  }
}
