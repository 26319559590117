import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, input } from '@angular/core';

import { AppStore } from '@state/stores/app.store';

import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss'],
  imports: [NgClass, AsyncPipe],
})
export class ReloadComponent {
  // Host bindings
  @HostBinding('class') class = 'cursor-pointer relative';

  // Inputs & Outputs
  colorize = input<boolean>(false);

  // Properties
  update$ = this.appStore.app.update;

  // Lifecycle hooks
  constructor(
    private readonly appStore: AppStore,
    private toastService: ToastService
  ) {}

  // Methods
  reload(): void {
    location.reload();
  }
}
