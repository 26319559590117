import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, map, mergeMap, Observable, of, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import { dateToUnix } from '@utils/time.utils';

import { RoomType } from '@enums/roomType.enum';

import {
  getRoomTypeFromServer,
  ServerRoomType,
} from './models/generic-room.dto';
import { GenericRoomMapper } from './models/generic-room.mapper';
import GetAllGenericRoomsResponseDTO from './models/get-all-generic-rooms.response.dto';
import GetRoomsChangesResponseDTO from './models/get-rooms-changes.response.dto';
import { Alert, instanceOfAlert } from '@models/alert.class';
import { Discover, instanceOfDiscover } from '@models/discover.class';
import { instanceOfOTO, OTO } from '@models/oto.class';
import { instanceOfRoom, Room } from '@models/room.class';
import RoomsChanges from '@models/rooms-changes.interface';

import { AlertsStore } from '@state/stores/alerts.store';
import { DiscoversStore } from '@state/stores/discovers.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';

import { DiscoversService } from '@services/api/discovers/discovers.service';
import { RoomsService } from '@services/api/rooms/rooms.service';

@Injectable({
  providedIn: 'root',
})
export class GenericRoomsService {
  private url: string;

  constructor(
    private http: HttpClient,
    private readonly otosStore: OTOsStore,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore,
    private readonly alertsStore: AlertsStore,
    private readonly roomsService: RoomsService,
    private readonly discoversService: DiscoversService
  ) {
    this.url = configuration.apiUrl;
  }

  private getAllGenericRooms(): Observable<RoomsChanges> {
    return this.http
      .get<GetAllGenericRoomsResponseDTO>(`${this.url}/users/me/alllistroom`)
      .pipe(
        map((response) => {
          return {
            rooms: response.list.map((room) => {
              if (room.type === ServerRoomType.Discover)
                return GenericRoomMapper.fromDTO({
                  ...room,
                  idDiscover: this.discoversStore.get(room.id)?.idDiscover,
                });
              return GenericRoomMapper.fromDTO(room);
            }),
            deleted: [],
          };
        })
      );
  }

  getAllOTOs(): Observable<OTO[]> {
    return this.getAllGenericRooms().pipe(
      map((changes) =>
        changes.rooms.filter((generic) => instanceOfOTO(generic))
      ),
      tap((otos) => this.otosStore.replaceCollection(otos))
    );
  }

  getAllRooms(): Observable<Room[]> {
    return this.getAllGenericRooms().pipe(
      map((changes) =>
        changes.rooms.filter((generic) => instanceOfRoom(generic))
      ),
      tap((rooms) => this.roomsStore.replaceCollection(rooms)),
      mergeMap((rooms) => {
        if (rooms.length === 0) return of([]);
        return forkJoin(
          rooms.map((room) =>
            this.roomsService.getMembers(room.id).pipe(
              map((members) => {
                room.members = members.members;
                room.administrators = members.administrators;
                return room;
              })
            )
          )
        );
      })
    );
  }

  getAllAlerts(): Observable<Alert[]> {
    return this.getAllGenericRooms().pipe(
      map((changes) =>
        changes.rooms.filter((generic) => instanceOfAlert(generic))
      ),
      tap((rooms) => this.alertsStore.replaceCollection(rooms)),
      mergeMap((rooms) => {
        if (rooms.length === 0) return of([]);
        return forkJoin(
          rooms.map((room) =>
            this.roomsService.getMembers(room.id).pipe(
              map((members) => {
                room.members = members.members;
                room.administrators = members.administrators;
                return room;
              })
            )
          )
        );
      })
    );
  }

  getAllDiscovers(): Observable<Discover[]> {
    return this.getAllGenericRooms().pipe(
      map((changes) =>
        changes.rooms.filter((generic) => instanceOfDiscover(generic))
      ),
      tap((discovers) => this.discoversStore.replaceCollection(discovers)),
      mergeMap((discovers) => {
        if (discovers.length === 0) return of([]);

        return forkJoin(
          discovers.map((discover) => {
            // TODO: Currently we need to be in a discover to get the members, this should be changed in the BackEnd in the future.
            if (discover.vinculated)
              return this.discoversService.getMembers(discover.id).pipe(
                map((members) => {
                  discover.members = members.members;
                  discover.administrators = members.administrators;
                  return discover;
                })
              );
            else return of(discover);
          })
        );
      })
    );
  }

  private getChanges(date: Date): Observable<RoomsChanges> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('from', dateToUnix(date).toString());

    return this.http
      .get<GetRoomsChangesResponseDTO>(
        `${this.url}/users/me/alllistroom/changes`,
        {
          params: queryParams,
        }
      )
      .pipe(
        map((response) => {
          return {
            rooms: response.all.map((room) => {
              if (room.type === ServerRoomType.Discover)
                return GenericRoomMapper.fromDTO({
                  ...room,
                  idDiscover: this.discoversStore.get(room.id)?.idDiscover,
                });
              return GenericRoomMapper.fromDTO(room);
            }),
            deleted: response.deleted.map((deleted) => {
              return {
                id: deleted.id,
                type: getRoomTypeFromServer(deleted.type),
              };
            }),
          };
        })
      );
  }

  getOTOsChanges(
    date: Date
  ): Observable<{ changed: OTO[]; deleted: number[] }> {
    return this.getChanges(date).pipe(
      map((changes) => ({
        changed: changes.rooms.filter((generic) => instanceOfOTO(generic)),
        deleted: changes.deleted
          .filter((deleted) => deleted.type === RoomType.OTO)
          .map((deleted) => deleted.id),
      })),
      tap(({ changed, deleted }) => {
        this.otosStore.add(changed, { override: true });
        this.otosStore.remove(deleted);
      })
    );
  }

  getRoomsChanges(
    date: Date
  ): Observable<{ changed: Room[]; deleted: number[] }> {
    return this.getChanges(date).pipe(
      map((changes) => ({
        changed: changes.rooms.filter((generic) => instanceOfRoom(generic)),
        deleted: changes.deleted
          .filter((deleted) => deleted.type === RoomType.Group)
          .map((deleted) => deleted.id),
      })),
      tap(({ changed, deleted }) => {
        this.roomsStore.add(changed, { override: true });
        this.roomsStore.remove(deleted);
      })
    );
  }

  getDiscoversChanges(
    date: Date
  ): Observable<{ changed: Discover[]; deleted: number[] }> {
    return this.getChanges(date).pipe(
      map((changes) => ({
        changed: changes.rooms.filter((generic) => instanceOfDiscover(generic)),
        deleted: changes.deleted
          .filter((deleted) => deleted.type === RoomType.Discover)
          .map((deleted) => deleted.id),
      })),
      tap(({ changed, deleted }) => {
        this.discoversStore.add(changed, { override: true });
        this.discoversStore.remove(deleted);
      })
    );
  }

  getAlertsChanges(
    date: Date
  ): Observable<{ changed: Alert[]; deleted: number[] }> {
    return this.getChanges(date).pipe(
      map((changes) => ({
        changed: changes.rooms.filter((generic) => instanceOfAlert(generic)),
        deleted: changes.deleted
          .filter((deleted) => deleted.type === RoomType.Alert)
          .map((deleted) => deleted.id),
      })),
      tap(({ changed, deleted }) => {
        this.alertsStore.add(changed, { override: true });
        this.alertsStore.remove(deleted);
      })
    );
  }

  getRoomById(id: number): Observable<Room | Discover> {
    if (this.discoversStore.get(id))
      return this.discoversService.getDiscoverById(id);
    else return this.roomsService.getRoomById(id);
  }
}
