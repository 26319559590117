import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component, computed, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { configuration } from '@configuration/configuration';

import { ChatService } from '@services/api/chat.service';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
  imports: [AsyncPipe, NgClass, NgStyle],
})
export class TitleBarComponent {
  // Host bindings
  @HostBinding('class') class =
    'flex w-full justify-center items-center px-4 py-2 overflow-hidden z-[10000]';

  // Properties
  configuration = configuration;
  unread$ = computed(() => this.chatService.unread$().chats);

  // Lifecycle hooks
  constructor(
    private readonly chatService: ChatService,
    private readonly title: Title
  ) {}
}
