import { ExecutionContext } from '@enums/execution-context.enum';

import { HardwareInfo } from '@models/hardware-info.class';
import Settings from '@models/settings.interface';
import { SystemInfo } from '@models/system-info.class';

import { version } from 'package.json';

export interface AppState {
  native?: boolean;
  app: {
    mode: ExecutionContext;
    version: string;
    update: 'available' | 'downloaded' | 'updated';
  };
  system?: SystemInfo;
  hardware?: HardwareInfo;
  connected: boolean;
  sockets?: {
    [key: string]: {
      connected: boolean;
      namespace?: string;
    };
  };
  settings?: Settings;
}

export const initialAppState: AppState = {
  app: {
    mode:
      '__TAURI__' in window || '__TAURI_INTERNALS__' in window
        ? ExecutionContext.Native
        : window.matchMedia('(display-mode: standalone)').matches
          ? ExecutionContext.PWA
          : ExecutionContext.Browser,
    version,
    update: 'updated',
  },
  system: {
    os: OS(),
    osVersion: window.navigator.userAgent,
    browser: browser(),
    browserVersion: browserVersion(),
  },
  connected: false,
};

function OS() {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    windows = ['Win32', 'Win64', 'Windows', 'WinCE'],
    macOS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    linux = ['Linux', 'X11'],
    android = ['Android'],
    iOS = ['iPhone', 'iPod'],
    iPadOS = ['iPad'];

  switch (true) {
    case /iPad/.test(userAgent):
      return 'iPadOS';
    case /iPhone/.test(userAgent):
      return 'iOS';
    case /Android/.test(userAgent):
      return 'Android';
    case /Mac/.test(platform):
      return 'MacOS';
    case /Win/.test(platform):
      return 'Windows';
    case /Linux/.test(platform):
      return 'Linux';
    default:
      return 'Unknown OS';
  }
}

function browser(): string {
  const userAgent = window.navigator.userAgent,
    browsers = [
      'Chrome',
      'Firefox',
      'Safari',
      'Opera',
      'MSIE',
      'Trident',
      'Edge',
    ];

  for (const browser of browsers) {
    if (new RegExp(browser, 'i').test(userAgent)) {
      return browser;
    }
  }
  return 'Unknown';
}

function browserVersion() {
  const userAgent = window.navigator.userAgent,
    browsers = [
      'Chrome',
      'Firefox',
      'Safari',
      'Opera',
      'MSIE',
      'Trident',
      'Edge',
    ];

  for (const browser of browsers) {
    const match = new RegExp(`${browser}/([\\d.]+)`, 'i').exec(userAgent);
    if (match) {
      return match[1];
    }
  }

  return 'Unknown';
}
