import { serverToClientDate } from '@utils/time.utils';

import GenericRoomDTO, { ServerRoomType } from './generic-room.dto';
import { Alert } from '@models/alert.class';
import { Discover } from '@models/discover.class';
import { OTO } from '@models/oto.class';
import { Room } from '@models/room.class';

export class GenericRoomMapper {
  static fromDTO(dto: GenericRoomDTO): OTO | Room | Discover | Alert {
    switch (dto.type) {
      case ServerRoomType.OTO:
        return new OTO({
          id: dto.id,
          name: dto.name,
          dates: {
            created: serverToClientDate(dto.createdAt),
            modified: serverToClientDate(dto.lastModification),
            lastEvent: serverToClientDate(dto.lastActivity),
          },
          unread: dto.readCount,
        });
      case ServerRoomType.Group:
        return new Room({
          id: dto.id,
          name: dto.name,
          dates: {
            created: serverToClientDate(dto.createdAt),
            modified: serverToClientDate(dto.lastModification),
            pictureChanged: serverToClientDate(dto.lastImageChange),
            lastEvent: serverToClientDate(dto.lastActivity),
          },
          bidirectional: dto.bidirectional === 1,
          unread: dto.readCount,
        });
      case ServerRoomType.Discover:
        return new Discover({
          id: dto.id,
          idDiscover: dto.idDiscover || 0,
          name: dto.name,
          dates: {
            created: serverToClientDate(dto.createdAt),
            modified: serverToClientDate(dto.lastModification),
            pictureChanged: serverToClientDate(dto.lastImageChange),
            lastEvent: serverToClientDate(dto.lastActivity),
          },
          bidirectional: dto.bidirectional === 1,
          unread: dto.readCount,
          vinculated: true,
        });
      case ServerRoomType.Alert:
        return new Alert({
          id: dto.id,
          name: dto.name,
          dates: {
            created: serverToClientDate(dto.createdAt),
            modified: serverToClientDate(dto.lastModification),
            pictureChanged: serverToClientDate(dto.lastImageChange),
            lastEvent: serverToClientDate(dto.lastActivity),
          },
          bidirectional: dto.bidirectional === 1,
          unread: dto.readCount,
        });
      default:
        throw new Error(`Unknown room type: ${dto.type}`);
    }
  }

  /*
  static toDTO(entity) {
    return {
      idUser: entity.idUser,
      name: entity.name,
      surname: entity.surname,
      authorized: entity.authorized,
    };
  }
  */
}
