import { FileExtension } from '@enums/fileExtensions.enum';
import { MessageType } from '@enums/messageType.enum';

import { FilesService } from '@services/files/files.service';

import { BaseFileMessage, IBaseFileMessage } from './base/FileMessage.base';

// ? Defined in the backend (config.adapter.ts)
enum AllowedExtensions {
  VCF = FileExtension.VCF,
  VCARD = FileExtension.VCARD,
  VCFX = FileExtension.VCFX,
}
export const AllowedMIMEs = Object.values(AllowedExtensions).map((ext) =>
  FilesService.mime(ext)
);

export interface IContactMessage extends IBaseFileMessage {
  type: MessageType.CONTACT;
  message: never;

  extension: AllowedExtensions;
  duration?: never;
  // size: number;
  url?: never;
  thumbnail?: never;
}

export class ContactMessage extends BaseFileMessage implements IContactMessage {
  override readonly type: MessageType.CONTACT = MessageType.CONTACT;
  override readonly message!: never;

  override readonly extension!: AllowedExtensions;
  override readonly duration?: never;
  // override readonly size!: number;
  override readonly url?: never;
  override readonly thumbnail?: never;

  constructor(
    message: Omit<IContactMessage, 'type' | 'extension'> & { extension: string }
  ) {
    super({ ...message, type: MessageType.CONTACT });
    // Object.assign(this, message); // ? This would override class properties of the base message.
    if (message) {
      // ! This if is necessary for plainToInstance from class-transformer to work
      this.extension = intoAllowedContactExtensions(message.extension);
    }
  }
}

function intoAllowedContactExtensions(extension: string) {
  const allowed = Object.values(AllowedExtensions);
  if (!allowed.includes(extension as AllowedExtensions)) {
    throw new Error(`Extension ${extension} is not allowed`);
  }
  return extension as AllowedExtensions;
}
